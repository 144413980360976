import { MatPaginatorIntl } from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
registerLocaleData(localeIT);
export var APP_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};
export var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
export function GetPaginatorIntl() {
    var paginator = new MatPaginatorIntl();
    paginator.itemsPerPageLabel = 'Items per pagina:';
    paginator.nextPageLabel = 'Pagina successiva';
    paginator.previousPageLabel = 'Pagina precedente';
    paginator.firstPageLabel = "Prima pagina";
    paginator.lastPageLabel = "Ultima pagina";
    paginator.getRangeLabel = function (page, pageSize, length) {
        var rangeLabel = "";
        if (length == 0 || pageSize == 0) {
            rangeLabel = "0 di 0";
        }
        else {
            var pageCount = Math.max(length, 0);
            var startIndex = page * pageSize;
            var endIndex = startIndex < pageCount ? Math.min(startIndex + pageSize, pageCount) : startIndex + pageSize;
            rangeLabel = (startIndex + 1).toString() + " - " + endIndex.toString() + " di " + pageCount.toString();
        }
        return rangeLabel;
    };
    return paginator;
}
var ɵ0 = GetPaginatorIntl();
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0 };
