<div class="edit-card" title="">
  <!-- header card -->
  <div class="edit-card-header container-padding">
    <table style="width:100%">
      <tr>
        <td align="left" valign="middle" style="width:32px">
          <i class="material-icons">{{this.Icon}}</i>
        </td>
        <td align="left" valign="middle">
          <span class="edit-card-title" style="position:relative;top:-4px">{{this.Title}}</span>
        </td>
      </tr>
    </table>
  </div>
  <div class="edit-card-body">
    <!-- content card -->
    <ng-content></ng-content>
  </div>
</div>
