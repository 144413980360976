import { Component, Input, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Input() iconOnly: boolean = false;
  @Input() sidenav;

  constructor(public navigatorService: NavigatorService) {
    if (this.sidenav != null)
      this.navigatorService.Sidenav = this.sidenav;
  }

  ngOnInit() {

  }

}
