import { OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(navigatorService) {
        this.navigatorService = navigatorService;
        this.Today = Date.now();
        this.SetTimeOfDay();
    }
    SidebarComponent.prototype.ngOnInit = function () {
    };
    SidebarComponent.prototype.SetTimeOfDay = function () {
        var _this = this;
        setInterval(function () {
            _this.Today = Date.now();
        }, 59000);
    };
    return SidebarComponent;
}());
export { SidebarComponent };
