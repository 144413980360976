<div class="view-container" title="">
  <div class="view-background container-background"></div>
  <div class="container container-padding container-fuild">

    <!-- header bar -->
    <div class="view-header-bar">
      <table style="width:100%">
        <tr>
          <td align="center" valign="top" style="padding-top:8px;width:32px">
            <i class="material-icons icon-title">{{this.Icon}}</i>
          </td>
          <td align="left" valign="top" style="padding-top:8px;">
            <span class="edit-breadcrumb">{{this.Title?.toUpperCase()}}</span>
          </td>
          <td align="right" valign="middle">
            <app-edit-search class="edit-search" (SearchChange)="SearchChange($event)"></app-edit-search>
          </td>
          <td *ngIf="this.SwitchMode" align="right" valign="middle" style="width:48px">
            <button mat-icon-button class="edit-button-icon" (click)="SetViewMode(ViewMode.Card)"><i class="material-icons icon-button-add">apps</i></button>
          </td>
          <td *ngIf="this.SwitchMode" align="right" valign="middle" style="width:48px">
            <button mat-icon-button class="edit-button-icon" (click)="SetViewMode(ViewMode.Table)"><i class="material-icons icon-button-add">view_headline</i></button>
          </td>
          <td *ngIf="this.Editing" align="right" valign="middle" style="width:48px">
            <button mat-icon-button class="edit-button-add" (click)="Add()"><i class="material-icons icon-button-add" matTooltip="Aggiungi">add</i></button>
          </td>
          <td align="right" valign="middle" style="width:48px">
            <button mat-icon-button class="edit-button-refresh" (click)="Refresh()"><i class="material-icons icon-button-refresh" matTooltip="Aggiorna">refresh</i></button>
          </td>
          <td *ngIf="this.navigatorService.CanGoBack && this.GoBackVisible" align="right" valign="middle" style="width:48px">
            <button mat-icon-button class="edit-button-back" (click)="GoBack()" matTooltip="Torna indietro"><i class="material-icons icon-button-back">arrow_back</i></button>
          </td>
        </tr>
      </table>
    </div>
    <hr />

    <!-- content data -->
    <div class="row">
      <div class="col-md-12">
        <ng-content></ng-content>
      </div>
    </div>

  </div>
</div>
