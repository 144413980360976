import { Component, Input, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';

@Component({
    selector: 'cdk-sidemenu-item',
    templateUrl: './sidemenu-item.component.html',
    styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent implements OnInit {

    @Input() menu;
    @Input() menus;
    @Input() iconOnly: boolean;
    @Input() secondaryMenu = false;
    @Input() sidenav;

    constructor(public navigatorService: NavigatorService) {
    }
    ngOnInit() {
    }

    HasChildMenu() {
        var hasChildMenu = (this.menu != null && this.menu.SubMenus != null) ? true : false;
        return hasChildMenu;
    }

    ShowMenu(menuSelected: any) {
        if (this.menus != null) {
            for (var menu of this.menus) {
                if (menu != menuSelected) {
                    menu.Open = false;
                    menu.Active = false;

                    var subMenus = menu.SubMenus;
                    if (subMenus != null) {
                        for (var subMenu of subMenus) {
                            subMenu.Open = false;
                            subMenu.Active = false;
                        }
                    }
                }
            }
        }
        menuSelected.Open = !menuSelected.Open;
        menuSelected.Active = true;

        if (this.navigatorService.SmartphoneMode && menuSelected.Link != null && this.navigatorService.Sidenav != null)
            this.navigatorService.Sidenav.toggle();

        if (menuSelected.Link != null)
            this.navigatorService.GoTo(menuSelected.Link);
    }

}
