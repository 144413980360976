<mat-sidenav-container fullscreen >

  <mat-sidenav [mode]="sideNavMode" class="sidenav" #sidenav [opened]="sideNavOpened" style="overflow: hidden;" >
    <mat-toolbar class="mat-elevation-z4 toolbar-logo">
      <table>
        <tr>
          <td align="left" valign="middle" style="width:48px;">
            <img src="../../assets/images/logo/logo.png" style="position:relative;left:-5px;width:48px;">
          </td>
          <td align="left" valign="middle">
            <table>
              <tr>
                <td align="left" valign="middle">
                  <h1 class="logo-title toolbar-application-title">
                    {{this.navigatorService.ApplicationShortName}}<strong class="toolbar-application-title-strong">{{this.navigatorService.ApplicationSuffixName}}</strong>  
                  </h1>
                </td>
              </tr>
              <tr>
                <td align="right" valign="middle">
                  <span class="version">{{ this.navigatorService.ApplicationVersion }}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </mat-toolbar>
    <cdk-sidemenu [sidenav]="sidenav" ></cdk-sidemenu>
  </mat-sidenav>

  <mat-sidenav-content style="z-index: unset;overflow: hidden;">
    <mat-drawer-container fullscreen>
      <mat-drawer mode="side" #drawer class="drawer" [opened]="matDrawerOpened" style="overflow: hidden!important;" >
        <mat-toolbar class="mat-elevation-z4 toolbar-logo">
          <img src="../../assets/images/logo/logo.png" style="width:48px;" title="{{this.navigatorService.ApplicationTitle}} versione {{ this.navigatorService.ApplicationVersion }}">
        </mat-toolbar>
        <cdk-sidemenu [iconOnly]="true" [sidenav]="sidenav"></cdk-sidemenu>
      </mat-drawer>
      <mat-drawer-content style="overflow: hidden!important;">
        <cdk-toolbar [sidenav]="sidenav" [drawer]="drawer" [sidebar]="sidenav2" [matDrawerShow]="matDrawerShow" style="z-index: 500"></cdk-toolbar>

        <perfect-scrollbar style="height: calc(100% - 46px);">
          <router-outlet></router-outlet>
        </perfect-scrollbar>

        <div class="container-bottom mat-elevation-z1">
          <table style="width:100%">
            <tr>
              <td align="center" valign="middle">
                <span class="container-text-bottom app-text-gray">{{this.navigatorService.ApplicationShortName}} {{this.navigatorService.ApplicationSuffixName}} | <a class="app-link">Guida Utente</a> | <a class="app-link" href="{{this.navigatorService.ApplicationContacts}}" target="_blank">Contatti</a></span>
              </td>
            </tr>
          </table>
        </div>

      </mat-drawer-content>
    </mat-drawer-container>
  </mat-sidenav-content>

  <mat-sidenav #sidenav2 position="end" mode="over">
    <cdk-sidebar></cdk-sidebar>
  </mat-sidenav>
</mat-sidenav-container>
                 
            
            
    
    









