import { Injectable } from '@angular/core';
import { EventModel } from '../models/EventModel';
import { DatePipe } from '@angular/common';
import { ValidationService } from './validation.service';

@Injectable()
export class CalendarService {

  public ViewMode: ViewMode = ViewMode.Month;
  public Date: Date = new Date();

  constructor(private datePipe: DatePipe, private validationService: ValidationService) {

  }

  public GetEvents(dtos: Array<any>, fieldId: string, fieldName: string, fieldStart: string, fieldEnd:string, fieldAllDay:string, fieldColor:string): Array<EventModel> { 
    if (dtos != null) {
      var events = new Array<EventModel>();
      for (var dto of dtos) {
        var event = this.GetEvent(dto, fieldId, fieldName, fieldStart, fieldEnd, fieldAllDay, fieldColor);
        if (event != null)
          events.push(event);
      }
      return events;
    }
    return null;
  }

  private GetEvent(dto: any, fieldId: string, fieldName: string, fieldStart: string, fieldEnd: string, fieldAllDay: string, fieldColor:string): EventModel {
    if (dto != null) {
      var allDay = dto[fieldAllDay];
      var event = new EventModel();
      event.id = dto[fieldId].toString();
      event.title = dto[fieldName];
      if (allDay)
        event.date = this.GetDateUTC(dto[fieldStart], true);
      else {
        event.start = this.GetDateTimeUTC(dto[fieldStart]);
        event.end = this.GetDateTimeUTC(dto[fieldEnd]);
      }
      event.backgroundColor = dto[fieldColor] || "blue";
      event.borderColor = dto[fieldColor] || "blue";
      event.textColor = "white";
      event.Dto = dto;
      return event;
    }
    return null;
  }

  public GetDateUTC(date: Date, withoutTime?: boolean): string {
    if (date != null && this.validationService.IsValidDate(date)) {
      var format: string = 'yyyy-MM-ddT00:00:00';
      if (withoutTime == true)
        format = 'yyyy-MM-dd';

      var dateMVC = this.datePipe.transform(date, format);
      return dateMVC;
    }
    return null;
  }

  public GetDateTimeUTC(dateTime?: Date): string {
    if (dateTime != null && this.validationService.IsValidDate(dateTime)) {
      var timeMVC = this.datePipe.transform(dateTime, 'yyyy-MM-ddTHH:mm:ss');
      return timeMVC ;
    }
    return null;
  }

  public GetDateAndTimeUTC(date: string, time: string): string {
    if (date != null) {
      var dateTime = new Date(date);
      if (time != null) {
        var timeSpan = this.GetTimeSpanFromDateTime(time);
        if (timeSpan != null) {
          dateTime.setHours(timeSpan.Hours, timeSpan.Minutes, timeSpan.Seconds);
        }
      }
     
      var dateTimeMVC = this.datePipe.transform(dateTime, 'yyyy-MM-ddTHH:mm:ss');
      return dateTimeMVC;
    }
    return null;
  }

  private GetTimeSpanFromDateTime(dateTime: string): TimeSpan {
    if (dateTime != null) {
      var date = new Date(dateTime);
      var timeSpan = new TimeSpan();
      timeSpan.Hours = date.getHours();
      timeSpan.Minutes = date.getMinutes();
      timeSpan.Seconds = date.getSeconds();
      return timeSpan;
    }
  }

  public GetTimeSpanFromTimeFormat(time: string, format: string): TimeSpan {
    if (time != null && format != null) {
      var timeSpan = new TimeSpan();
      if (format == Format.AMPM) {
        var splits = time.split(" ");
        if (splits.length >= 2) {
          var timeAMPM = splits[0];
          var meridian = splits[1];
          timeSpan = this.GetTimeSpanFromTime(timeAMPM);
          if (meridian.toUpperCase() == Meridian.PM) {
            timeSpan.Hours += 12;
          }
        }
      } else {
        timeSpan = this.GetTimeSpanFromTime(time);
      }
      return timeSpan;
    }
    return null;
  }

  private GetTimeSpanFromTime(time: string): TimeSpan {
    if (time != null) {
      var splits = time.split(":");
      var hours = 0;
      var minutes = 0;
      var seconds = 0;
      if (splits.length >= 1) {
        hours = parseInt(splits[0]);
        if (splits.length >= 2)
          minutes = parseInt(splits[1]);
        if (splits.length >= 3)
          seconds = parseInt(splits[2]);

        var timeSpan = new TimeSpan();
        timeSpan.Hours = hours;
        timeSpan.Minutes = minutes;
        timeSpan.Seconds = seconds;
        return timeSpan;
      }
    }
    return null;
  }

  public GetTimeFromDateTime(dateTime: string, format: string): string {
    if (dateTime != null && format != null) {
      var timeSpan = this.GetTimeSpanFromDateTime(dateTime);
      if (timeSpan != null) {
        var hours = timeSpan.Hours;
        var minutes = timeSpan.Minutes;
        var meridian = (format == Format.AMPM ? (hours <= 12 ? "AM" : "PM") : "");
        if (format == Format.AMPM && hours > 12)
          hours -= 12;

        var hoursFormat = (hours < 10 ? "0" : "") + hours.toString();
        var minutesFormat = (minutes < 10 ? "0" : "") + minutes.toString();
        var time = hoursFormat + ":" + minutesFormat + " " + meridian;
        return time;
      }
    }
    return null;
  }

  public GetDateTimeFromTimeFormat(time: string, format: string): Date {
    if (time != null && time.length>=1 && format != null) {
      var timeSpan = this.GetTimeSpanFromTimeFormat(time, format);
      if (timeSpan != null) {
        var dateTime = new Date();
        dateTime.setHours(timeSpan.Hours, timeSpan.Minutes, timeSpan.Seconds);
        return dateTime;
      }
    }
    return null;
  }

  public GetDaysInMonth(year: number, month: number): number {
    if (year > 0 && month >= 0) {
      var daysInMonth = new Date(year, month+1, 0).getDate();
      return daysInMonth;
    }
    return null;
  }

  public AddDays(date: Date, days: number): Date {
    if (date != null && days != null) {
      var newDate = new Date(date);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    }
    return null;
  }


    public GetDateFormat(date: Date, format: string): string {
        if (date != null && format != null) {
            var dateFormat = this.datePipe.transform(date, format);
            return dateFormat;
        }
        return null;
    }
}

export class TimeSpan {
  public Hours: number;
  public Minutes: number;
  public Seconds: number;
}

export enum Format {
  AMPM = "12",
  H24 = "24"
}
export enum Meridian {
  AM = "AM",
  PM = "PM"
}

export class DateRange {
  public Start: Date;
  public End: Date;
}

export enum ViewMode {
  Month = "dayGridMonth",
  Week = "timeGridWeek",
  Day = "timeGridDay"
}


