var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/timeout';
import { SpinnerService } from './spinner.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PopupComponent, DataDialog } from '../popup/popup.component';
import { Paging } from '../base/BaseModel';
import { DataService } from './data.service';
var NavigatorService = /** @class */ (function () {
    function NavigatorService(dataService, router, spinnerService, location, sanitizer, titleService, dialog, snackBar, activatedRoute) {
        var _this = this;
        this.dataService = dataService;
        this.router = router;
        this.spinnerService = spinnerService;
        this.location = location;
        this.sanitizer = sanitizer;
        this.titleService = titleService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.activatedRoute = activatedRoute;
        this.SecurityWorkflow = false;
        this.UserInfo = null;
        //variabili user-data
        this.LinkTelefonico = false;
        this.TelefonoAssistenza = null;
        this.ApplicationStarted = false;
        this.InitNavigationInterceptor();
        this.Breadcrumbs = new Array();
        this.location.subscribe(function (q) { return _this.GoBackPopState(q); }); //intercept --> evento Browser Back
        //this.router.events.subscribe(q => this.InitRefresh(q)); //intercept --> evento Browser Refresh
    }
    Object.defineProperty(NavigatorService.prototype, "CanGoBack", {
        get: function () {
            var goBack = false;
            if (this.Breadcrumbs != null)
                goBack = this.Breadcrumbs.length >= 2;
            return goBack;
        },
        enumerable: true,
        configurable: true
    });
    NavigatorService.prototype.ngOnInit = function () {
    };
    NavigatorService.prototype.InitNavigationInterceptor = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            _this.NavigationInterceptor(event);
        });
    };
    NavigatorService.prototype.NavigationInterceptor = function (event) {
        if (event instanceof NavigationStart) {
            this.Start();
        }
        if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
            this.Stop();
        }
    };
    NavigatorService.prototype.IsAuthenticated = function () {
        return __awaiter(this, void 0, void 0, function () {
            var authenticated, model, dto;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        authenticated = false;
                        return [4 /*yield*/, this.dataService.GetAuthentication()];
                    case 1:
                        model = _a.sent();
                        if (model != null && model.Performed) {
                            dto = model.Dto;
                            if (dto != null) {
                                authenticated = dto.Authenticated;
                            }
                        }
                        return [2 /*return*/, authenticated];
                }
            });
        });
    };
    NavigatorService.prototype.InitApplicationParameters = function () {
        var title = this.ApplicationTitle;
        this.titleService.setTitle(title);
    };
    NavigatorService.prototype.SetUserData = function (model) {
        if (model != null) {
            var dto = model.Dto;
            if (dto != null) {
                var image = (dto.Image != null ? "stage/" + dto.Image : "avatars/nouser.png");
                this.UrlImage = this.sanitizer.bypassSecurityTrustStyle("url(../../../assets/images/" + image + ")");
                this.Nickname = dto.Nickname;
            }
        }
    };
    NavigatorService.prototype.Start = function () {
        this.spinnerService.Show();
    };
    NavigatorService.prototype.Stop = function () {
        this.spinnerService.Hide();
    };
    NavigatorService.prototype.ShowPdf = function (urlPdfFile) {
        this.UrlPdfFile = urlPdfFile;
        this.GoTo("/container/pdfvisualizer");
    };
    NavigatorService.prototype.GoTo = function (action, dto, filter, extras) {
        if (dto == null) { //view --> azzeramento dati di Paging/Sortings
            this.Paging = new Paging();
            this.Sortings = new Array();
        }
        var breadcrumb = new Breadcrumb();
        breadcrumb.Action = action;
        breadcrumb.Dto = dto;
        breadcrumb.Filter = filter;
        this.Breadcrumbs.push(breadcrumb);
        this.Action = action;
        this.Dto = dto;
        this.Filter = filter;
        this.router.navigate([action], extras);
    };
    NavigatorService.prototype.GoBack = function () {
        this.location.back();
    };
    NavigatorService.prototype.GoBackPopState = function (navigation) {
        if (navigation != null) {
            var type = navigation.type;
            //todo: da rivedere action==action --> var action = navigation.url;
            if (type == "popstate") {
                var breadcrumbs = this.Breadcrumbs;
                if (breadcrumbs != null) {
                    breadcrumbs.pop();
                    var count = breadcrumbs.length;
                    if (count >= 1) {
                        var lastBreadcrumb = breadcrumbs[count - 1];
                        if (lastBreadcrumb != null) {
                            this.Dto = lastBreadcrumb.Dto;
                            this.Filter = lastBreadcrumb.Filter;
                        }
                    }
                }
            }
        }
    };
    //todo: da rivedere
    NavigatorService.prototype.InitRefresh = function (event) {
        if (event != null && event instanceof NavigationStart) {
            if (!this.router.navigated) {
                if (!this.ApplicationStarted) {
                    console.log("start");
                    this.ApplicationStarted = true;
                }
                else {
                    console.log("refresh");
                }
            }
        }
    };
    NavigatorService.prototype.ShowPopup = function (title, message, confirmCallback) {
        var dataDialog = new DataDialog();
        dataDialog.Title = title;
        dataDialog.Message = message;
        dataDialog.ConfirmCallback = confirmCallback;
        this.dialog.open(PopupComponent, { data: dataDialog });
    };
    NavigatorService.prototype.ShowMessage = function (message, action) {
        this.snackBar.open(message, action, { duration: 2000, });
    };
    NavigatorService.prototype.GetQueryParameter = function (parameter) {
        if (this.activatedRoute != null) {
            var snapshot = this.activatedRoute.snapshot;
            if (snapshot != null) {
                var queryParameters = snapshot.queryParamMap;
                if (queryParameters != null) {
                    var value = queryParameters.get(parameter);
                    return value;
                }
            }
        }
        return null;
    };
    return NavigatorService;
}());
export { NavigatorService };
var Breadcrumb = /** @class */ (function () {
    function Breadcrumb() {
    }
    return Breadcrumb;
}());
export { Breadcrumb };
