import { OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { NavigatorService } from '../services/navigator.service';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(navigatorService, dataService) {
        this.navigatorService = navigatorService;
        this.dataService = dataService;
        this.searchOpen = false;
    }
    ToolbarComponent.prototype.ngOnInit = function () {
    };
    ToolbarComponent.prototype.Logout = function () {
        var _this = this;
        //avvio procedura di logout utente
        this.navigatorService.Start();
        this.dataService.Logout().then(function (model) {
            _this.navigatorService.GoTo("/login");
            _this.navigatorService.Stop();
        });
    };
    ToolbarComponent.prototype.SetSidebarTab = function (tabIndex) {
        this.sidebar.toggle();
        this.navigatorService.SidebarTabIndex = tabIndex;
    };
    ToolbarComponent.prototype.ToggleSidenav = function () {
        this.sidenav.toggle();
        if (this.matDrawerShow) {
            this.drawer.toggle();
        }
        this.navigatorService.Sidenav = this.sidenav;
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
