import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, BaseDataService } from '../base/BaseDataService';
import { Paging } from '../base/BaseModel';
import { NotificationModel, NotificationFilter } from '../models/NotificationModel';
import { UserModel, UserFilter } from '../models/UserModel';

export enum Controller {
    Security = "Security",
    Notification = "Notification",
}

@Injectable()
export class DataService extends BaseDataService {

    constructor(http: HttpClient) {
        super(http);
    }


    public async Login(username: string, password: string): Promise<UserModel> {
        var request = new UserModel();
        request.Filter = new UserFilter();
        request.Filter.Username = username;
        request.Filter.Password = password;
        var response = await this.AsyncPost(Controller.Security, Action.Login, request);
        return response;
    }

    public async Logout(): Promise<UserModel> {
        var request = new UserModel();
        var response = await this.AsyncPost(Controller.Security, Action.Logout, request);
        return response;
    }

    public async GetAuthentication(): Promise<UserModel> {
        var request = new UserModel();
        var response = await this.AsyncPost(Controller.Security, Action.Authentication, request);
        return response;
    }

    public async LoadNotifications(paging: Paging): Promise<NotificationModel> {
        var request = new NotificationModel();
        request.Paging = paging;
        var response = await this.AsyncPost(Controller.Notification, Action.Load, request);
        return response;
    }

    public async LoadEffectiveNotifications(paging: Paging): Promise<NotificationModel> {
        var response = await this.Load(Controller.Notification, paging, [], "", { 'Status': 'Creata' });
        return response;
    }
}
