import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {

    private Timeout: number = 10000; //timeout in secondi per lo spinner
    public Running: boolean = false;

    constructor() {
    }

    public Show() {
        this.Running = true;

        setTimeout(() => {
            if (this.Running)
                this.Hide();
        }, this.Timeout);
    }

    public Hide() {
        this.Running = false;
    }
}

//export class Runner {
//    public Time: Date;

//    constructor(time: Date) {
//        this.Time = time;
//    }
//}
