<app-view-container Title="Dashboard" Icon="home" [Editing]="false" [GoBackVisible]="false">

  <div class="row">

    <div class="col-md-6">

      <!-- Statistiche pratiche-->
      <div class="row">
        <div class="col-md-12">
          <app-edit-card Title="Elaborazione Pratiche (ultimi 3 anni)" Icon="all_inbox">
            <table style="width:100%; position:relative; left:5px;">
              <tr>
                <td align="center" style="width:33%">
                  <span class="app-number app-text-blue">{{abbreviateNumber(this.Statistic?.CountPraticheTotali)}}</span>
                  <p class="app-card-text">Totali</p>
                </td>
                <td align="center" style="width:33%">
                  <span class="app-number app-text-orange">{{abbreviateNumber(this.Statistic?.CountPraticheAperte)}}</span>
                  <p class="app-card-text">Aperte</p>
                </td>
                <td align="center" style="width:33%">
                  <span class="app-number app-text-green">{{abbreviateNumber(this.Statistic?.CountPraticheChiuse)}}</span>
                  <p class="app-card-text">Chiuse</p>
                </td>
                <td align="center" style="width:33%">
                  <div class="app-number app-text-red" title="Fai clic per visualizzare le pratiche da verificare..." (click)="ShowPraticheVerificare()">{{abbreviateNumber(this.Statistic?.CountPraticheErrore)}}</div>
                  <p class="app-card-text">Verificare</p>
                </td>
              </tr>
            </table>
            <div style="width:100%; height:200px;">
              <ngx-charts-bar-vertical-stacked [scheme]="this.ColorScheme" [results]="this.PraticheStatistics" [gradient]="true" [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false" [xAxisLabel]="'Anni'" [yAxisLabel]="'Case'" [barPadding]="50"></ngx-charts-bar-vertical-stacked>
            </div>
          </app-edit-card>
        </div>
      </div>

      <!-- Statistiche mandanti/debitori -->
      <div class="row">
        <div class="col-md-6">
          <app-edit-card Title="Mandanti" Icon="card_travel">
            <span class="app-number app-text-blue">{{abbreviateNumber(this.Statistic?.CountMandanti)}}</span>
            <p class="app-card-text">Totali</p>
          </app-edit-card>
        </div>
        <div class="col-md-6">
          <app-edit-card Title="Debitori" Icon="people">
            <span class="app-number app-text-blue">{{abbreviateNumber(this.Statistic?.CountDebitori)}}</span>
            <p class="app-card-text">Totali</p>
          </app-edit-card>
        </div>
      </div>

      <!-- Situazione pagamenti -->
      <div class="row">
        <div class="col-md-12">
          <app-edit-card Title="Situazione Pagamenti" Icon="all_inbox">
            <table style="width:100%; position:relative; left:20px;">
              <tr>
                <td align="center" style="width:25%">
                  <span class="app-number app-text-blue">{{abbreviateNumber(this.Statistic?.CountPagamentiTotali)}}</span>
                  <p class="app-card-text">Totali</p>
                </td>
                <td align="center" style="width:25%">
                  <span class="app-number app-text-orange">{{abbreviateNumber(this.Statistic?.CountPagamentiRifiutati)}}</span>
                  <p class="app-card-text">Rifiutati</p>
                </td>
                <td align="center" style="width:25%">
                  <span class="app-number app-text-green">{{abbreviateNumber(this.Statistic?.CountPagamentiAutorizzati)}}</span>
                  <p class="app-card-text">Autorizzati</p>
                </td>
                <td align="center" style="width:25%">
                  <div class="app-number app-text-red" title="Fai clic per visualizzare i pagamenti da verificare..." (click)="ShowPagamentiVerificare()">{{abbreviateNumber(this.Statistic?.CountPagamentiVerificare)}}</div>
                  <p class="app-card-text">Verificare</p>
                </td>
              </tr>
            </table>
            <div style="width:100%; height:200px;">
              <ngx-charts-pie-grid [scheme]="this.ColorScheme" [results]="this.PagamentiStatistics" label="Totale"></ngx-charts-pie-grid>
            </div>
          </app-edit-card>
        </div>
      </div>

      <!-- Performance del sistema -->
      <div class="row">
        <div class="col-md-12">
          <app-edit-card Title="Performance (KPI)" Icon="storage">
            <table style="width:100%;">
              <tr>
                <td>
                  <span class="app-number app-text-blue">{{this.Statistic?.CountRichiesteLandingPage}}<span class="app-card-text app-text-green"></span></span>
                  <p class="app-card-text">Richieste Totali</p>
                </td>
                <td>
                  <span class="app-number app-text-orange">{{this.Statistic?.CountRichiesteOTP}}<span class="app-card-text app-text-green"> / {{this.Statistic?.CountRichiesteLandingPage}}</span></span>
                  <p class="app-card-text">Richieste OTP / Contatti LP</p>
                </td>
              </tr>
            </table>
            <div style="width:100%; height:300px;">
              <ngx-charts-gauge [scheme]="this.ColorScheme" [results]="this.RichiesteStatistics" units="Numero Richieste" [legend]="false" [max]="this.Statistic?.CountRichiesteTotali" [showAxis]="false"></ngx-charts-gauge>
            </div>
          </app-edit-card>
        </div>
      </div>

    </div>

    <div class="col-md-6">

      <!-- Statistiche comunicazioni -->
      <div class="row">
        <div class="col-md-12">
          <app-edit-card Title="Statistiche Comunicazioni" Icon="all_inbox">
            <table style="width:100%; position:relative;">
              <tr>
                <td align="center" style="width:25%">
                  <span class="app-number app-text-blue">{{abbreviateNumber(this.Statistic?.CountComunicazioniTotali)}}</span>
                  <p class="app-card-text">Totali</p>
                </td>
                <td align="center" style="width:33%">
                  <span class="app-number app-text-orange">{{abbreviateNumber(this.Statistic?.CountComunicazioniSMS)}}</span>
                  <p class="app-card-text">SMS</p>
                </td>
                <td align="center" style="width:33%">
                  <span class="app-number app-text-green">{{abbreviateNumber(this.Statistic?.CountComunicazioniEmail)}}</span>
                  <p class="app-card-text">Email</p>
                </td>
                <td align="center" style="width:33%">
                  <span class="app-number app-text-red">{{abbreviateNumber(this.Statistic?.CountComunicazioniErrore)}}</span>
                  <p class="app-card-text">Errore</p>
                </td>
              </tr>
            </table>
            <div style="width:100%; height:200px;">
              <ngx-charts-pie-chart [scheme]="this.ColorScheme" [results]="this.ComunicazioniStatistics" [gradient]="true" [legend]="true" [legendTitle]="null"></ngx-charts-pie-chart>
            </div>
          </app-edit-card>
        </div>
      </div>

      <!-- Calendario attività -->
      <div class="row">
        <div class="col-md-12">
          <app-edit-card Title="Calendario Attività" Icon="calendar_today">
            <mat-calendar #calendar [dateClass]="GetHighlightDates()" (click)="MonthChange(calendar)"></mat-calendar>
          </app-edit-card>
        </div>
      </div>

      <!-- Avvisi -->
      <div class="row">
        <div class="col-md-12">
          <app-edit-card Title="Avvisi" Icon="error">
            <table style="width:100%;">
              <tr *ngFor="let dto of this.WhatsNew?.Dtos" class="app-table-row">
                <td align="left" valign="middle" style="position:relative;width:20px; padding-top:5px;">
                  <i class="material-icons" style="font-size:16px;">{{dto.AllDay==true? 'event' : 'query_builder'}}</i>
                </td>
                <td align="left" valign="middle" style="padding-left:10px;">
                  <span title="{{dto.Description}}">{{dto.Name}}</span>
                </td>
                <td align="right" valign="middle">
                  <span><em>{{dto.Start | date: (dto.AllDay==true?'dd/MM/yyyy':'dd/MM/yyyy HH:mm')}}</em></span>
                </td>
              </tr>
            </table>
          </app-edit-card>
        </div>
      </div>

    </div>

  </div>

</app-view-container>

