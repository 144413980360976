import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-pagamento-chatbot-message',
  templateUrl: './pagamento-chatbot-message.component.html',
  styleUrls: ['./pagamento-chatbot-message.component.scss']
})

export class PagamentoChatbotMessageComponent implements OnInit, AfterViewInit {
  @Input()
  public Direction: string; //[left|right]

  @Input()
  public Message: string;

  @Input()
  public Scrollbar?: PerfectScrollbarComponent;

  public Timestamp: Date;

  public get Image(): string {
    var direction = this.Direction;
    var image = direction == "left" ? "chatbot_icon.png" : "chatbot_user.png";
    return image;
  }

  public get Username(): string {
    var direction = this.Direction;
    var username = direction == "left" ? "Chatbot" : "Tu";
    return username;
  }

  public Writing: boolean = true;

  constructor() {
    this.Timestamp = new Date();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    var timeout = (this.Direction == "left" ? 2000 : 125);

    setTimeout(() => {
      this.Writing = false;

      //scroll auto
      if (this.Scrollbar != null) {
        setTimeout(() => {
          this.Scrollbar.directiveRef.scrollToBottom(0, 250);
        }, 500);
      }
    }, timeout);
  }
}
