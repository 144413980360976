import { EventEmitter } from "@angular/core";
var OneDriveService = /** @class */ (function () {
    function OneDriveService() {
        this.Selected = new EventEmitter();
    }
    OneDriveService.prototype.Open = function () {
        var self = this;
        var options = {
            clientId: "db4aab0b-556c-4bd7-9fa5-89733a4a72b7",
            action: "share",
            multiSelect: false,
            openInNewWindow: true,
            advanced: {
            //filter: "folder,.png" /* display folder and files with extension '.png' only */
            //filter: "folder,photo"
            },
            success: function (response) {
                if (response != null) {
                    var value = response.value;
                    if (value != null && value.length >= 1) {
                        var file = value[0];
                        if (file != null) {
                            var item = new OneDriveItem();
                            item.Name = file.name;
                            item.Url = file.webUrl;
                            self.Selected.emit(item);
                        }
                    }
                }
            },
            cancel: function () { },
            error: function (e) {
                console.log(e);
            }
        };
        OneDrive.open(options);
    };
    return OneDriveService;
}());
export { OneDriveService };
var OneDriveItem = /** @class */ (function () {
    function OneDriveItem() {
    }
    Object.defineProperty(OneDriveItem.prototype, "Value", {
        get: function () {
            return JSON.stringify(this);
        },
        enumerable: true,
        configurable: true
    });
    return OneDriveItem;
}());
export { OneDriveItem };
