import { Routes } from "@angular/router";
import { ContainerComponent } from "./core/container/container.component";
import { LoginComponent } from "./core/security/login/login.component";
import { ExternalComponent } from "./core/external/external.component";
import { DashboardHomeComponent } from "./dashboard/dashboard-home/dashboard-home.component";

export const FileSizeMaxUpload: number = 4096000; //dimensione massima consentita file upload [bytes]

export const AllowedFileTypes: Array<string> = ["pdf", "jpg", "jpeg", "png", "bmp", "gif", "tif"];

export const ApplicationMenus = [    //menu applicativo laterale sx
  {
    'Name': 'Dashboard',
    'ShortName': 'Dashboard',
    'Link': '/container/dashboard/home',
    'Icon': 'home',
    'Open': false,
    'Active': true,
    'Chip': false,
  }, {
    'Name': 'Agenda',
    'ShortName': 'Agenda',
    'Link': '/container/scheduler/view',
    'Icon': 'calendar_today',
    'Open': false,
    'Active': false,
    'Chip': false,
  }, {
    'Name': 'Mandanti',
    'ShortName': 'Mandanti',
    'Link': '/container/mandante/view',
    'Icon': 'card_travel',
    'Open': false,
    'Active': false,
    'Chip': false,
    //}, {
    //    'Name': 'Debitori',
    //    'ShortName': 'Debitori',
    //    'Link': '/container/debitore/view',
    //    'Icon': 'people',
    //    'Open': false,
    //    'Active': false,
    //    'Chip': false,
    //}, {
    //    'Name': 'Pratiche',
    //    'ShortName': 'Pratiche',
    //    'Link': '/container/pratica/view',
    //    'Icon': 'event_note',
    //    'Open': false,
    //    'Active': false,
    //    'Chip': false,
  }, {
    'Name': 'Template',
    'ShortName': 'Template',
    'Link': '/container/templatenotifica/view',
    'Icon': 'library_books',
    'Open': false,
    'Active': false,
    'Chip': false,
  }, {
    'Name': 'Reports',
    'ShortName': 'Reports',
    'Link': '/container/report/home',
    'Icon': 'insert_chart',
    'Open': false,
    'Active': false,
    'Chip': false,
  }, {
    'Name': 'Faq',
    'ShortName': 'Faq',
    'Link': '/container/faq/view',
    'Icon': 'help',
    'Open': false,
    'Active': false,
    'Chip': false,
  }, {
    'Name': 'Impostazioni',
    'ShortName': 'Impostazioni',
    'Link': '/container/settings/view',
    'Icon': 'settings',
    'Chip': false,
    'Active': false,
    'Open': false,
  },{
    'Name': 'Stato Pagamenti',
    'ShortName': 'StatoPagamenti',
    'Link': '/container/pagamento/stato',
    'Icon': 'attach_money',
    'Chip': false,
    'Active': false,
    'Open': false,
  },
];

export const ApplicationRoutes: Routes = [
  {
    path: 'container', component: ContainerComponent, children: [  //baseref on route -> container/#module#
      { path: 'dashboard', loadChildren: 'app/dashboard/dashboard.module#DashboardModule' },
      { path: 'report', loadChildren: 'app/report/report.module#ReportModule' },
      { path: 'scheduler', loadChildren: 'app/scheduler/scheduler.module#SchedulerModule' },
      { path: 'mandante', loadChildren: 'app/mandante/mandante.module#MandanteModule' },
      { path: 'prodotto', loadChildren: 'app/prodotto/prodotto.module#ProdottoModule' },
      { path: 'sistemapagamento', loadChildren: 'app/sistemapagamento/sistemapagamento.module#SistemaPagamentoModule' },
      { path: 'sistemanotifica', loadChildren: 'app/sistemanotifica/sistemanotifica.module#SistemaNotificaModule' },
      { path: 'pratica', loadChildren: 'app/pratica/pratica.module#PraticaModule' },
      { path: 'comunicazione', loadChildren: 'app/comunicazione/comunicazione.module#ComunicazioneModule' },
      { path: 'posizionedebitoria', loadChildren: 'app/posizionedebitoria/posizionedebitoria.module#PosizioneDebitoriaModule' },
      { path: 'pagamento', loadChildren: 'app/pagamento/pagamento.module#PagamentoModule' },
      { path: 'debitore', loadChildren: 'app/debitore/debitore.module#DebitoreModule' },
      { path: 'providernotifica', loadChildren: 'app/providernotifica/providernotifica.module#ProviderNotificaModule' },
      { path: 'templatenotifica', loadChildren: 'app/templatenotifica/templatenotifica.module#TemplateNotificaModule' },
      { path: 'modellocomunicazione', loadChildren: 'app/modellocomunicazione/modellocomunicazione.module#ModelloComunicazioneModule' },
      { path: 'settings', loadChildren: 'app/settings/settings.module#SettingsModule' },
      { path: 'faq', loadChildren: 'app/faq/faq.module#FaqModule' },
      { path: '', component: DashboardHomeComponent, pathMatch: 'full' },
    ]
  },
  {
    path: 'external', component: ExternalComponent, children: [
      { path: 'pagamento', loadChildren: 'app/pagamento/pagamento.module#PagamentoModule' },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'login' },
];

export enum ProviderNotifica {
  Email = "Email",
  SMS = "SMS",
  PEC = "PEC"
}

export enum ModelloNotifica {
  ComunicazioneOrdinaria = "Comunicazione Ordinaria",
  SollecitoPagamento = "Sollecito Pagamento",
  InvioDatiPagamento = "Invio Dati Pagamento",
  Reminder = "Reminder",
  Informativa = "Informativa",
}

export enum ProviderPagamento {
  PagOnline = "PagOnline",
  Paypal = "Paypal",
  SISAL = "SISAL",
  ContoCorrentePostale = "Conto Corrente Postale",
  BonificoBancario = "Bonifico Bancario",
  Lottomatica = "Lottomatica",
  Assegno = "Assegno",
  Cambiale = "Cambiale",
  CartaCredito = "Carta di Credito",
  AmazonPay = "Amazon Pay",
  ApplePay = "Apple Pay",
  Scalapay = "Scalapay"

}

export enum Debitore {
  PersonaFisica = "Persona Fisica",
  PersonaGiuridica = "Persona Giuridica"
}

export enum Pagamento {
  Nessuno = "Nessuno",
  Effettuato = "Effettuato",
  Online = "Online",
  Mandante = "Mandante",
  Rifiutato = "Rifiutato"
}

export enum OpzionePagamento {
  Nessuna = "Nessuna",
  Dilazione = "Dilazione Pagamento",
  Parziale = "Pagamento Parziale",
  PDR = "Piano di Rientro",
  SaldoStralcio = "Saldo Stralcio",
  SaldoStralcioRateale = "Saldo Stralcio Rateale",
  Rifiutata = "Rifiutata"
}

export enum TemplateNotifica {
  Html = "Html",
  SMS = "SMS",
  //Word = "Word",
  //Excel = "Excel",
  //PDF = "PDF"
}

export enum Richiesta {
  SMS = "SMS",
  Email = "Email",
  PEC = "PEC"
}

export enum Rifiuto {
  DifficoltaEconomiche = "Difficolta Economiche",
  ContestazioneFattura = "Contestazione Fattura",
  ContestazionePagamento = "Contestazione Pagamento",
  Altro = "Altro"
}

export enum Comunicazione {
  Creata,
  Inviata,
  Errore
}

export enum Notification {
  LandingPage = "Atterraggio pagina",
  OTPAuthentication = "Autenticazione OTP"
}

export enum Report {
  StatistichePratiche = "Statistiche Pratiche",
  StatisticheLandingPage = "Statistiche Landing Page",
  StatistichePagamenti = "Statistiche Pagamenti"
}

export enum Role {
  Supervisore = "Supervisore",
  UtenteR = "UtenteR",
  UtenteA = "UtenteA",
  UtenteC = "UtenteC"
}

export enum StatoPratica {
  Creata = "Creata",
  InvioNotifica = "Invio Notifica",
  Notificata = "Notificata",
  PagamentoEffettuato = "Pagamento Effettuato",
  PagamentoOnline = "Pagamento Online",
  PagamentoMandante = "Pagamento Mandante",
  Rifiutata = "Rifiutata",
  RichiestaContatto = "Richiesta Contatto",
  Rateizzata = "Rateizzata",
  Dilazionata = "Dilazionata",
  Scaduta = "Scaduta",
  Errore = "Errore",
  Verificare = "Verificare"
}

export enum StatoPagamento {
  Creato = "Creato",
  Autorizzato = "Autorizzato",
  Negato = "Negato",
  Rifiutato = "Rifiutato",
  Verificare = "Verificare",
  Annullato = "Annullato",
}

export enum TipoEvento {
  Pagamento = "Pagamento",
  Scadenza = "Scadenza",
  Affidamento = "Affidamento"
}
