
export class BaseModel<TEntity extends BaseEntity, TDto extends TEntity, TFilter extends TDto> implements IBaseModel {
  public Paging: Paging;
  public Sortings: Array<Sorting>;
  public Count: number;
  public Search: string;
  public Filter: TFilter;
  public Dtos: TDto[];
  public Dto: TDto;
  public Entity: TEntity;
  public Properties: Array<string>;
  public Performed: boolean;

  constructor() {
    this.Paging = new Paging();
    this.Sortings = new Array<Sorting>();
    this.Count = 0;
    this.Search = null;
    this.Performed = false;
  }
}

export class BaseEntity {
    public set Id(value: string) {
        this.id = value;
    }
    public get Id(): string {
        return this.id;
    }
    public id: string;
}

export interface IBaseModel {
  Paging: Paging;
  Sortings: Array<Sorting>;
  Count: number;
  Search: string;
  Filter: any;
  Dtos: Array<any>;
  Dto: any;
  Entity: any;
  Properties: Array<string>;
  Performed: boolean;
}

export enum Direction {
  None = "None",
  Ascending = "Ascending",
  Descending = "Descending"
}

export class Sorting {
  public Name: string;
  public Direction: Direction;
}

export class Paging {
  public Page: number;
  public Take: number;
  public get Skip(): number {
    var skip = this.Page * this.Take;
    return skip;
  }

  constructor(take?: number) {
    this.Page = 0;
    this.Take = take || 5;
  }
}


