import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService } from './core/base/BaseDataService';

export enum Controller {
  Dashboard = "Dashboard",
  Statistic = "Statistic",
  Settings = "Settings",
  Storage = "Storage",
  WhatsNew = "WhatsNew",
  Mandante = "Mandante",
  Payment = "Payment",
  Pratica = "Pratica",
  Prodotto = "Prodotto",
  Debitore = "Debitore",
  Pagamento = "Pagamento",
  Documento = "Documento",
  SistemaPagamento = "SistemaPagamento",
  SistemaNotifica = "SistemaNotifica",
  Comunicazione = "Comunicazione",
  PosizioneDebitoria = "PosizioneDebitoria",
  ProviderNotifica = "ProviderNotifica",
  TemplateNotifica = "TemplateNotifica",
  ModelloComunicazione = "ModelloComunicazione",
  Enumeration = "Enumeration",
  Security = "Security",
  Notification = "Notification",
  Report = "Report",
  Faq = "Faq",
  Identificazione = "Identificazione",
}

export enum Action {
  ReadToken = "ReadToken",
  Verify = "Verify",
  Execute = "Execute",
  Build = "Build",
  Clone = "Clone",
  Search = "Search",
  UpdateStato = "UpdateStato",
  SendPagamentoEffettuato = "SendPagamentoEffettuato",
  SendPagamentoMandante = "SendPagamentoMandante",
  SendPagamentoDilazionato = "SendPagamentoDilazionato",
  SendPagamentoParziale = "SendPagamentoParziale",
  SendPagamentoPDR = "SendPagamentoPDR",
  SendPagamentoSaldoStralcio = "SendPagamentoSaldoStralcio",
  SendPagamentoSaldoStralcioRateale = "SendPagamentoSaldoStralcioRateale",
  SendDocumento = "SendDocumento",
  LoadProviderPagamento = "LoadProviderPagamento",
  SendRifiutoPagamento = "SendRifiutoPagamento",
  LoadRifiutoPagamento = "LoadRifiutoPagamento",
  SendCodiceSicurezza = "SendCodiceSicurezza",
  SendNotification = "SendNotification",
}

@Injectable()
export class DataService extends BaseDataService {
  constructor(public http: HttpClient) {
    super(http);
  }

}

