import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material';
import { Paging, Sorting } from '../../core/base/BaseModel';
import { BarValueModel, StatisticBarModel } from '../../core/models/StatisticBarModel';
import { CalendarService } from '../../core/services/calendar.service';
import { NavigatorService } from '../../core/services/navigator.service';
import { Controller, DataService } from '../../data.service';
import { StatisticModel } from '../../models/StatisticModel';
import { StorageModel } from '../../models/StorageModel';
import { WhatsNewModel, WhatsNewFilter } from '../../models/WhatsNewModel';
import { PraticaFilter } from '../../models/PraticaModel';
import { PagamentoFilter } from '../../models/PagamentoModel';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})

export class DashboardHomeComponent implements AfterViewInit { 

  @ViewChild('calendar') Calendar: MatCalendar<Date>;

  public ColorScheme = {
    domain: ['#7ED321', '#F5A623', '#ff2d2d', '#AAAAAA']
  };

  public WhatsNew: WhatsNewModel;
  public Scheduler: WhatsNewModel;
  public Statistic: StatisticModel;
  public PraticheStatistics: Array<StatisticBarModel>;
  public ComunicazioniStatistics: Array<BarValueModel>;
  public PagamentiStatistics: Array<BarValueModel>;
  public RichiesteStatistics: Array<BarValueModel>;

  constructor(private dataService: DataService, public navigatorService: NavigatorService, private calendarService: CalendarService) {
    this.navigatorService.IsAuthenticated().then((authenticated) => {
      if (authenticated) {
        this.LoadStatistic();
        this.LoadWhatsNews();
      }
      else
        this.navigatorService.GoTo("/login");
    });
  }

  ngAfterViewInit() {

  }

  private async LoadStatistic() {
    this.Statistic = await this.dataService.Get<StatisticModel>(Controller.Statistic);
    this.PraticheStatistics = this.GetPraticheStatistics(this.Statistic);
    this.ComunicazioniStatistics = this.GetComunicazioniStatistics(this.Statistic);
    this.PagamentiStatistics = this.GetPagamentiStatistics(this.Statistic);
    this.RichiesteStatistics = this.GetRichiesteStatistics(this.Statistic);
  }

  private async LoadWhatsNews() {
    this.WhatsNew = await this.dataService.Load<WhatsNewModel>(Controller.WhatsNew, <Paging>{ Skip: 0, Take: 5 }, <Sorting[]>[{ Name: "Start", Direction: "Ascending" }], null, <WhatsNewFilter>{ Start: new Date(), Alert: true });
    this.Scheduler = await this.dataService.Get<WhatsNewModel>(Controller.WhatsNew, null, <WhatsNewFilter>{ Date: new Date() });
    this.Calendar.updateTodaysDate();
  }

  private GetPraticheStatistics(statistic: StatisticModel): StatisticBarModel[] {
    var statisticsBar = new Array<StatisticBarModel>();
    if (statistic != null) {
      var dtos = statistic.StatisticheAnno;
      if (dtos != null) {
        for (var dto of dtos) {
          var statisticBar = new StatisticBarModel();
          statisticBar.name = dto.Anno.toString();

          var series = new Array<BarValueModel>();
          series.push({ name: "Chiuse", value: dto.PraticheChiuse })
          series.push({ name: "Aperte", value: dto.PraticheAperte })
          series.push({ name: "Verificare", value: dto.PraticheErrore })
          statisticBar.series = series;

          statisticsBar.push(statisticBar);
        }
      }
    }

    return statisticsBar;
  }

  public GetComunicazioniStatistics(statistic: StatisticModel): BarValueModel[] {
    var bars = new Array<BarValueModel>();
    if (statistic != null) {
      bars.push(<BarValueModel>{ name: "Email", value: statistic.CountComunicazioniEmail });
      bars.push(<BarValueModel>{ name: "SMS", value: statistic.CountComunicazioniSMS });
      bars.push(<BarValueModel>{ name: "Errore", value: statistic.CountComunicazioniErrore });
    }
    return bars;
  }

  public GetPagamentiStatistics(statistic: StatisticModel): BarValueModel[] {
    var bars = new Array<BarValueModel>();
    if (statistic != null) {
      bars.push(<BarValueModel>{ name: "Autorizzati", value: statistic.CountPagamentiAutorizzati });
      bars.push(<BarValueModel>{ name: "Rifiutati", value: statistic.CountPagamentiRifiutati });
      bars.push(<BarValueModel>{ name: "Verificare", value: statistic.CountPagamentiVerificare });
    }
    return bars;
  }

  public GetRichiesteStatistics(statistic: StatisticModel): BarValueModel[] {
    var bars = new Array<BarValueModel>();
    if (statistic != null) {
      bars.push(<BarValueModel>{ name: "Contatti LP", value: statistic.CountRichiesteLandingPage });
      bars.push(<BarValueModel>{ name: "Richieste OTP", value: statistic.CountRichiesteOTP });
    }
    return bars;
  }


  public GetHighlightDates() {
    return (date: Date): MatCalendarCellCssClasses => {
      var className = "";
      if (this.Scheduler != null) {
        var dtos = this.Scheduler.Dtos;
        if (dtos != null) {
          var dateUTC = this.calendarService.GetDateUTC(date);
          var datesUTC = dtos.map(q => this.calendarService.GetDateUTC(q.Start));
          var highlightDate = datesUTC.find(q => q == dateUTC) != null;
          if (highlightDate)
            className = "mat-calendar-date-highlight";
        }
      }
      return className;
    };
  }

  public async MonthChange(calendar: MatCalendar<Date>) {
    var view = calendar.monthView;
    if (view != null) {
      var date = view.activeDate;
      if (date != null) {
        this.Scheduler = await this.dataService.Get<WhatsNewModel>(Controller.WhatsNew, null, <WhatsNewFilter>{ Date: new Date(date) });
        this.Calendar.updateTodaysDate();
      }
    }
  }

  public ShowPraticheVerificare() {
    this.navigatorService.GoTo("/container/pratica/view", null, <PraticaFilter>{ Verificare: true});
  }

  public ShowPagamentiVerificare() {
    this.navigatorService.GoTo("/container/pagamento/view", null, <PagamentoFilter>{Verificare:true});
  }

  
  public abbreviateNumber(number){
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}


}
