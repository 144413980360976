import { BaseUserModel, BaseUserEntity } from "../base/BaseUserModel";

export class UserModel extends BaseUserModel<UserEntity, UserDto, UserFilter> {

  constructor() {
    super();

  }
}

export class UserEntity extends BaseUserEntity {

}

export class UserDto extends UserEntity {

}

export class UserFilter extends UserDto {

}
