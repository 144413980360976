import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-edit-search',
    templateUrl: './edit-search.component.html',
    styleUrls: ['./edit-search.component.scss']
})

export class EditSearchComponent {

    private Timer: any;
    private Timeout: number = 500; // 500ms timeout di default
    public SearchWaiting: boolean = false;
    public Opened: boolean = false;

    @Output()
    public SearchChange: EventEmitter<string>;


    constructor() {
        this.SearchChange = new EventEmitter<string>();
    }

    public SearchingAsync(input: HTMLInputElement) {
        if (input != null) {
            this.SearchWaiting = true;

            var search = input.value;
            if (this.Timer != null)
                clearTimeout(this.Timer);

            this.Timer = setTimeout(() => {
                this.SearchWaiting = false;
                this.Searching(search);
            }, this.Timeout);
        }
    }

    public Searching(search: string) {
        this.SearchChange.emit(search);
    }

}
