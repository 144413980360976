import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { NavigatorService } from '../../services/navigator.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(navigatorService, dataService) {
        this.navigatorService = navigatorService;
        this.dataService = dataService;
        this.AccessDenied = false;
        this.LoginWaiting = false;
        this.ValidationMessage = null;
    }
    LoginComponent.prototype.ngOnInit = function () {
    };
    LoginComponent.prototype.EnterKey = function (username, password) {
        this.Login(username, password);
    };
    LoginComponent.prototype.Login = function (username, password) {
        var _this = this;
        var validation = this.Validation(username, password);
        if (validation) {
            this.LoginWaiting = true;
            this.dataService.Login(username, password).then(function (model) {
                if (model != null && model.Dto != null && model.Dto.Authenticated) {
                    _this.AccessDenied = false;
                    _this.navigatorService.UserInfo = model.Dto;
                    _this.navigatorService.GoTo('/container/dashboard/home'); //todo: inserire in configuration
                }
                else {
                    _this.navigatorService.UserInfo = null;
                    _this.AccessDenied = true;
                    _this.LoginWaiting = false;
                    _this.ValidationMessage = "Accesso non consentito!";
                }
            });
        }
    };
    LoginComponent.prototype.Validation = function (username, password) {
        var validation = true;
        if (username == null || username.length == 0) {
            this.AccessDenied = true;
            this.ValidationMessage = "Specificare il nome utente";
            validation = false;
        }
        else if (password == null || password.length == 0) {
            this.AccessDenied = true;
            this.ValidationMessage = "Occorre inserire una password";
            validation = false;
        }
        else if (password.length < 5 || password.length > 15) {
            this.AccessDenied = true;
            this.ValidationMessage = "La password deve avere una lunghezza compresa tra 5 e 15 caratteri";
            validation = false;
        }
        else {
            var formControl = new FormControl(password, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$'));
            var valid = formControl.valid;
            this.AccessDenied = !valid;
            this.ValidationMessage = (valid ? "" : "La password deve contenere un numero, una lettera maiuscola e almeno un carattere speciale");
            validation = valid;
        }
        return validation;
    };
    LoginComponent.prototype.Forgot = function () {
        this.navigatorService.GoTo('/forgot');
    };
    return LoginComponent;
}());
export { LoginComponent };
