export class EventModel {
  public id: string;
  public title: string;
  public date: string;
  public start: string;
  public end: string;
  public backgroundColor: string;
  public borderColor: string;
  public textColor: string;
  public Dto: any;
}

