import { BaseModel, BaseEntity } from "../core/base/BaseModel";


export class FaqModel extends BaseModel<FaqEntity, FaqDto, FaqFilter> {


  constructor() {
    super();

  }
}


export class FaqEntity extends BaseEntity {
  public Codice: string;
  public Domanda: string;
  public Risposta: string;

}

export class FaqDto extends FaqEntity {

}

export class FaqFilter extends FaqDto {

}
