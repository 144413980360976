import { BaseModel, BaseEntity } from "../base/BaseModel";

export class BaseUserModel<TEntity extends BaseUserEntity, TDto extends TEntity, TFilter extends TDto> extends BaseModel<TEntity, TDto, TFilter> {


  constructor() {
    super();

  }
}


export class BaseUserEntity extends BaseEntity {
  public Username: string;
  public Password: string;
  public Image: string;
  public Authenticated?: boolean;
  public Nickname: string;
  public Role: string;
}

