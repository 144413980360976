var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseUserModel, BaseUserEntity } from "../base/BaseUserModel";
var UserModel = /** @class */ (function (_super) {
    __extends(UserModel, _super);
    function UserModel() {
        return _super.call(this) || this;
    }
    return UserModel;
}(BaseUserModel));
export { UserModel };
var UserEntity = /** @class */ (function (_super) {
    __extends(UserEntity, _super);
    function UserEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserEntity;
}(BaseUserEntity));
export { UserEntity };
var UserDto = /** @class */ (function (_super) {
    __extends(UserDto, _super);
    function UserDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserDto;
}(UserEntity));
export { UserDto };
var UserFilter = /** @class */ (function (_super) {
    __extends(UserFilter, _super);
    function UserFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserFilter;
}(UserDto));
export { UserFilter };
