<div class="container-login" >
  <div class="container-image">
    <table style="width:100%; height:100%">
      <tr>
        <td align="center" valign="middle">
          <img class="logo-image" src="../../../../assets/images/bg/logo-login.png" />
        </td>
      </tr>
    </table>
  </div>
  <div class="container-authentication">
    <table style="width:100%; height:100%">
      <tr>
        <td align="center" valign="middle">
          <div class="panel-row">
            <img src="../../../assets/images/logo/logo.png" />
          </div>
          <div class="panel-row">
            <h3 class="login-title app-text-blue">{{this.navigatorService.ApplicationShortName}} <span class="app-text-gray">{{this.navigatorService.ApplicationSuffixName}}</span></h3>
          </div>
          <div style="width:50%">
            <div class="panel-row">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Username</mat-label>
                <input type="text" #username class="input" placeholder="" required matInput [readonly]="this.LoginWaiting">
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline" style="position:relative;top:-20px;">
                <mat-label>Password</mat-label>
                <input type="password" #password class="input" placeholder="" required matInput [readonly]="this.LoginWaiting" (keyup.enter)="EnterKey(username.value, password.value)">
              </mat-form-field>
            </div>
            <div class="panel-row">
              <button mat-raised-button class="button-login" type="submit" [disabled]="this.LoginWaiting" (click)="Login(username.value, password.value)">ACCEDI <mat-icon>arrow_forward</mat-icon></button>
            </div>
            <div class="panel-row">
              <!--<button mat-button class="login-forgot" [disabled]="this.LoginWaiting" (click)="Forgot()">Password dimenticata?</button>-->
            </div>
            <div class="panel-row">
              <span *ngIf="this.AccessDenied" style="font-size:12px; color:red;">{{ this.ValidationMessage }}</span>
            </div>
            <div class="panel-row">
              <mat-progress-bar *ngIf="this.LoginWaiting" mode="indeterminate"></mat-progress-bar>
            </div>
          </div>

        </td>
      </tr>
    </table>
    <div class="container-login-bottom">
      <table style="width:100%">
        <tr>
          <td align="right" valign="middle">
            <span class="login-text-bottom app-text-gray">{{this.navigatorService.ApplicationShortName}} {{this.navigatorService.ApplicationSuffixName}} | <a class="app-link">Guida Utente</a> | <a class="app-link">Contatti</a></span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>





