import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatDatepickerModule, MatOptionModule, MatProgressBarModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatTabsModule, MatTooltipModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatSnackBarModule, MatTableModule, MatPaginatorModule, MatPaginatorIntl, MatSortModule, MatProgressSpinnerModule, MatBadgeModule } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ContainerComponent } from './container/container.component';
import { EditContainerComponent } from './edit-container/edit-container.component';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { PdfVisualizerComponent } from './pdfvisualizer/pdfvisualizer.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LoginComponent } from './security/login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidemenuItemComponent } from './sidemenu-item/sidemenu-item.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TablePagerComponent } from './table-pager/table-pager.component';
import { ToolbarNavigationComponent } from './toolbar-navigation/toolbar-navigation.component';
import { ToolbarNotificationComponent } from './toolbar-notification/toolbar-notification.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { NavigatorService } from './services/navigator.service';
import { DataService } from './services/data.service';
import { SpinnerService } from './services/spinner.service';
import { ViewContainerComponent } from './view-container/view-container.component';
import { CalendarService } from './services/calendar.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EditControlTextComponent } from './edit-control-text/edit-control-text.component';
import { EditControlDropdownListComponent } from './edit-control-dropdown-list/edit-control-dropdown-list.component';
import { EditControlDateComponent } from './edit-control-date/edit-control-date.component';
import { EditControlTimeComponent } from './edit-control-time/edit-control-time.component';
import { EditControlDateTimeComponent } from './edit-control-datetime/edit-control-datetime.component';
import { EditControlToggleComponent } from './edit-control-toggle/edit-control-toggle.component';
import { EditCardComponent } from './edit-card/edit-card.component';
import { PopupComponent } from './popup/popup.component';
import { ViewCalendarComponent } from './view-calendar/view-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FileUploadModule } from 'ng2-file-upload';
import { ViewTableComponent } from './view-table/view-table.component';
import { TableColumnComponent } from './table-column/table-column.component';
import { EditSearchComponent } from './edit-search/edit-search.component';
import { ValidationService } from './services/validation.service';
import { EditControlLinkComponent } from './edit-control-link/edit-control-link.component';
import { OneDriveService } from './services/onedrive.services';
import { EditControlOneDrivePickerComponent } from './edit-control-onedrive-picker/edit-control-onedrive-picker.component';
import { ExternalComponent } from './external/external.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditControlEditorComponent } from './edit-control-editor/edit-control-editor.component';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { EditControlImageComponent } from './edit-control-image/edit-control-image.component';
import { EditControlTextAreaComponent } from './edit-control-textarea/edit-control-textarea.component';
import { PagamentoChatbotMessageComponent } from '../pagamento/pagamento-chatbot-message/pagamento-chatbot-message.component';
import { PagamentoChatbotComponent } from '../pagamento/pagamento-chatbot/pagamento-chatbot.component';
import { PagamentoAssistenzaComponent } from '../pagamento/pagamento-assistenza/pagamento-assistenza.component';
registerLocaleData(localeIT);

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function GetPaginatorIntl() {
  var paginator = new MatPaginatorIntl();
  paginator.itemsPerPageLabel = 'Items per pagina:';
  paginator.nextPageLabel = 'Pagina successiva';
  paginator.previousPageLabel = 'Pagina precedente';
  paginator.firstPageLabel = "Prima pagina";
  paginator.lastPageLabel = "Ultima pagina";
  paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
    var rangeLabel = "";
    if (length == 0 || pageSize == 0) {
      rangeLabel = "0 di 0";
    } else {
      var pageCount = Math.max(length, 0);
      var startIndex = page * pageSize;
      var endIndex = startIndex < pageCount ? Math.min(startIndex + pageSize, pageCount) : startIndex + pageSize;
      rangeLabel = (startIndex + 1).toString() + " - " + endIndex.toString() + " di " + pageCount.toString();
    }
    return rangeLabel;
  }
  return paginator;
}

@NgModule({
  declarations: [
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarNotificationComponent,
    ToolbarComponent,
    SearchBarComponent,
    FullscreenComponent,
    SidebarComponent,
    UserMenuComponent,
    ToolbarNavigationComponent,
    TablePagerComponent,
    EditCardComponent,
    EditContainerComponent,
    EditControlTextComponent,
    EditControlTextAreaComponent,
    EditControlDropdownListComponent,
    EditControlDateComponent,
    EditControlTimeComponent,
    EditControlDateTimeComponent,
    EditControlToggleComponent,
    EditControlLinkComponent,
    EditControlImageComponent,
    EditControlOneDrivePickerComponent,
    EditControlEditorComponent,
    EditSearchComponent,
    SpinnerComponent,
    PopupComponent,
    PdfVisualizerComponent,
    LoginComponent,
    ContainerComponent,
    ExternalComponent,
    ViewContainerComponent,
    ViewCalendarComponent,
    ViewTableComponent,
    TableColumnComponent,
    PagamentoChatbotComponent,
    PagamentoChatbotMessageComponent,
    PagamentoAssistenzaComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatTabsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    FullCalendarModule,
    CKEditorModule,
    FileUploadModule
  ],
  exports: [
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarNotificationComponent,
    ToolbarComponent,
    SearchBarComponent,
    FullscreenComponent,
    SidebarComponent,
    UserMenuComponent,
    ToolbarNavigationComponent,
    TablePagerComponent,
    EditCardComponent,
    EditContainerComponent,
    EditControlTextComponent,
    EditControlTextAreaComponent,
    EditControlDropdownListComponent,
    EditControlDateComponent,
    EditControlTimeComponent,
    EditControlDateTimeComponent,
    EditControlToggleComponent,
    EditControlLinkComponent,
    EditControlImageComponent,
    EditControlOneDrivePickerComponent,
    EditControlEditorComponent,
    EditSearchComponent,
    SpinnerComponent,
    PopupComponent,
    PdfVisualizerComponent,
    LoginComponent,
    ContainerComponent,
    ExternalComponent,
    ViewContainerComponent,
    ViewCalendarComponent,
    ViewTableComponent,
    TableColumnComponent,
    PagamentoChatbotComponent,
    PagamentoChatbotMessageComponent,
    PagamentoAssistenzaComponent,
  ],
  providers: [
    //NavigatorService,
    DataService, //todo: eliminare (spostare BaseDataService --> service)
    SpinnerService,
    CalendarService,
    ValidationService,
    OneDriveService,
    DatePipe,
    DecimalPipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MatPaginatorIntl, useValue: GetPaginatorIntl() },
    { provide: LOCALE_ID, useValue: 'it-IT' }
  ],
  entryComponents: [PopupComponent]
})

export class CoreModule { }
