import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { NavigatorService } from '../../services/navigator.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public AccessDenied: boolean = false;
  public LoginWaiting: boolean = false;
  public ValidationMessage: string = null;

  constructor(public navigatorService: NavigatorService, private dataService: DataService) {

  }

  ngOnInit() {
  }


  EnterKey(username: string, password: string) {
    this.Login(username, password);
  }

  Login(username: string, password: string) {
    var validation = this.Validation(username, password);
    if (validation) {
      this.LoginWaiting = true;
      this.dataService.Login(username, password).then(model => {
        if (model != null && model.Dto != null && model.Dto.Authenticated) {
          this.AccessDenied = false;
          this.navigatorService.UserInfo = model.Dto;
          this.navigatorService.GoTo('/container/dashboard/home'); //todo: inserire in configuration
        } else {
          this.navigatorService.UserInfo = null;
          this.AccessDenied = true;
          this.LoginWaiting = false;
          this.ValidationMessage = "Accesso non consentito!";
        }
      });
    }
  }

  Validation(username: string, password: string): boolean {
    var validation = true;
    if (username == null || username.length == 0) {
      this.AccessDenied = true;
      this.ValidationMessage = "Specificare il nome utente";
      validation = false;
    } else if (password == null || password.length == 0) {
      this.AccessDenied = true;
      this.ValidationMessage = "Occorre inserire una password";
      validation = false;
    } else if (password.length < 5 || password.length > 15) {
      this.AccessDenied = true;
      this.ValidationMessage = "La password deve avere una lunghezza compresa tra 5 e 15 caratteri";
      validation = false;
    } else {
      var formControl = new FormControl(password, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$'));
      var valid = formControl.valid;
      this.AccessDenied = !valid;
      this.ValidationMessage = (valid ? "" : "La password deve contenere un numero, una lettera maiuscola e almeno un carattere speciale");
      validation = valid;
    }
    return validation;
  }

  Forgot() {
    this.navigatorService.GoTo('/forgot');
  }

}

