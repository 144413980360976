var StatisticBarModel = /** @class */ (function () {
    function StatisticBarModel() {
    }
    return StatisticBarModel;
}());
export { StatisticBarModel };
var BarValueModel = /** @class */ (function () {
    function BarValueModel() {
    }
    return BarValueModel;
}());
export { BarValueModel };
