/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i13 from "@angular/material/toolbar";
import * as i14 from "@angular/common";
import * as i15 from "../toolbar-notification/toolbar-notification.component.ngfactory";
import * as i16 from "../toolbar-notification/toolbar-notification.component";
import * as i17 from "../services/data.service";
import * as i18 from "../user-menu/user-menu.component.ngfactory";
import * as i19 from "../user-menu/user-menu.component";
import * as i20 from "../services/navigator.service";
import * as i21 from "./toolbar.component";
var styles_ToolbarComponent = [i0.styles];
var RenderType_ToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarComponent, data: {} });
export { RenderType_ToolbarComponent as RenderType_ToolbarComponent };
function View_ToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["mat-icon-button", ""], ["matTooltip", "Espande o chiude il men\u00F9 laterale..."]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.ToggleSidenav() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "i", [["class", "material-icons app-toolbar-menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["menu "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "Espande o chiude il men\u00F9 laterale..."; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleSidenav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "i", [["class", "material-icons app-toolbar-menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["menu "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "mat-toolbar", [["class", "mat-elevation-z4 mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i12.View_MatToolbar_0, i12.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i13.MatToolbar, [i1.ElementRef, i4.Platform, i14.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ToolbarComponent_1)), i1.ɵdid(4, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ToolbarComponent_2)), i1.ɵdid(6, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "span", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "cdk-toolbar-notification", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event, $event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ToolbarNotificationComponent_0, i15.RenderType_ToolbarNotificationComponent)), i1.ɵdid(9, 114688, null, 0, i16.ToolbarNotificationComponent, [i1.ElementRef, i17.DataService], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "cdk-user-menu", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event, $event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_UserMenuComponent_0, i18.RenderType_UserMenuComponent)), i1.ɵdid(11, 114688, null, 0, i19.UserMenuComponent, [i1.ElementRef, i20.NavigatorService, i17.DataService], null, null), (_l()(), i1.ɵeld(12, 16777216, null, 0, 5, "button", [["class", "toolbar-button-mini"], ["mat-button", ""], ["matTooltip", "Visualizza maggiori informazioni..."]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.SetSidebarTab(0) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(14, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "i", [["class", "material-icons app-toolbar-menu toolbar-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["info_outlined "])), (_l()(), i1.ɵeld(17, 0, null, 0, 0, "span", [["class", "toolbar-text"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.matDrawerShow; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.matDrawerShow; _ck(_v, 6, 0, currVal_3); _ck(_v, 9, 0); _ck(_v, 11, 0); var currVal_6 = "Visualizza maggiori informazioni..."; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 13).disabled || null); var currVal_5 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_ToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cdk-toolbar", [], null, null, null, View_ToolbarComponent_0, RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i21.ToolbarComponent, [i20.NavigatorService, i17.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarComponentNgFactory = i1.ɵccf("cdk-toolbar", i21.ToolbarComponent, View_ToolbarComponent_Host_0, { sidenav: "sidenav", sidebar: "sidebar", drawer: "drawer", matDrawerShow: "matDrawerShow" }, {}, []);
export { ToolbarComponentNgFactory as ToolbarComponentNgFactory };
