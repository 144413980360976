<div class="toolbar-user-container">
  <table style="width:100%">
    <tr>
      <td valign="middle">
        <button mat-button (click)="isOpen = !isOpen" class="toolbar-user-btn" [class.open]="isOpen" [ngStyle.xs]="{'min-width': '70px', 'height': '32px'}" style="position:relative; top:0px;height:40px;">
          <span fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="toolbar-icon" >person_outline</mat-icon>
            <span class="name" fxHide fxShow.gt-xs>{{this.navigatorService.Nickname || 'User not Connected'}}</span>
            <mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
          </span>
        </button>
      </td>
    </tr>
  </table>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <mat-nav-list>
        <!--<mat-list-item>
          <a matLine>Profilo</a>
          <button mat-icon-button>
            <mat-icon class="toolbar-icon">account_circle</mat-icon>
          </button>
        </mat-list-item>-->
        <!--<mat-list-item>
          <a matLine>Impostazioni</a>
          <button mat-icon-button>
            <mat-icon class="toolbar-icon">settings</mat-icon>
          </button>
        </mat-list-item>-->
        <mat-list-item>
          <a matLine href="../../../assets/file/manual/user-guide.pdf" target="_blank">Help online</a>
          <button mat-icon-button>
            <mat-icon class="toolbar-icon">help</mat-icon>
          </button>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item (click)="Logout()">
          <a matLine>Disconnetti</a>
          <button mat-icon-button>
            <mat-icon class="toolbar-icon">exit_to_app</mat-icon>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>

</div>
