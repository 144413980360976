import { Component } from '@angular/core';
import { ApplicationMenus } from './app.configuration';
import { NavigatorService } from './core/services/navigator.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],

})
export class AppComponent {
    constructor(private navigatorService: NavigatorService) {
        //impostazione parametri
        this.navigatorService.ApplicationVersion = "Versione 5.0.5";
        this.navigatorService.ApplicationShortName = "Recupero";
        this.navigatorService.ApplicationSuffixName = "Crediti";
        this.navigatorService.ApplicationTitle = "FIDES - Recupero Crediti";
        this.navigatorService.ApplicationCompany = "FIDES S.p.A.";
        this.navigatorService.ApplicationContacts = "https://www.fidesspa.eu/contatti/";
        this.navigatorService.ApplicationPrivacy = "https://www.fidesspa.eu/privacy-policy/";
        this.navigatorService.Contact = { Phone: "+39 095.50091", Email: "support@fidesspa.eu" };
        this.navigatorService.Menus = ApplicationMenus;

        //inizializzazione sistema
        this.navigatorService.InitApplicationParameters();
    }
}
