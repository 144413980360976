/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidemenu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../sidemenu-item/sidemenu-item.component.ngfactory";
import * as i5 from "../sidemenu-item/sidemenu-item.component";
import * as i6 from "../services/navigator.service";
import * as i7 from "../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i8 from "ngx-perfect-scrollbar";
import * as i9 from "@angular/common";
import * as i10 from "./sidemenu.component";
var styles_SidemenuComponent = [i0.styles];
var RenderType_SidemenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidemenuComponent, data: {} });
export { RenderType_SidemenuComponent as RenderType_SidemenuComponent };
function View_SidemenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxLayoutAlign", "space-around center"]], [[4, "margin", null]], null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "image-rounded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["style", "width:100%; height:100%; background-repeat: no-repeat no-repeat; background-size:cover "]], [[4, "background-image", null]], null, null, null, null))], function (_ck, _v) { var currVal_1 = "space-around center"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "10px 0px"; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.navigatorService.UrlImage; _ck(_v, 3, 0, currVal_2); }); }
function View_SidemenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxLayoutAlign", "space-around center"], ["style", "height: 100px;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "image-rounded-mini"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["style", "width:100%; height:100%; background-repeat: no-repeat no-repeat; background-size:cover "]], [[4, "background-image", null]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "space-around center"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.navigatorService.UrlImage; _ck(_v, 3, 0, currVal_1); }); }
function View_SidemenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cdk-sidemenu-item", [], null, null, null, i4.View_SidemenuItemComponent_0, i4.RenderType_SidemenuItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.SidemenuItemComponent, [i6.NavigatorService], { menu: [0, "menu"], menus: [1, "menus"], iconOnly: [2, "iconOnly"], sidenav: [3, "sidenav"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_co.navigatorService == null) ? null : _co.navigatorService.Menus); var currVal_2 = _co.iconOnly; var currVal_3 = _co.sidenav; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SidemenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "perfect-scrollbar", [["class", "sidemenu-background"], ["style", "height: calc(100% - 33px);"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i7.View_PerfectScrollbarComponent_0, i7.RenderType_PerfectScrollbarComponent)), i1.ɵdid(1, 507904, null, 0, i8.PerfectScrollbarComponent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidemenuComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidemenuComponent_2)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidemenuComponent_3)), i1.ɵdid(9, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = "column"; _ck(_v, 3, 0, currVal_2); var currVal_3 = !_co.iconOnly; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.iconOnly; _ck(_v, 7, 0, currVal_4); var currVal_5 = ((_co.navigatorService == null) ? null : _co.navigatorService.Menus); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).autoPropagation; var currVal_1 = i1.ɵnov(_v, 1).scrollIndicators; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SidemenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cdk-sidemenu", [], null, null, null, View_SidemenuComponent_0, RenderType_SidemenuComponent)), i1.ɵdid(1, 114688, null, 0, i10.SidemenuComponent, [i6.NavigatorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidemenuComponentNgFactory = i1.ɵccf("cdk-sidemenu", i10.SidemenuComponent, View_SidemenuComponent_Host_0, { iconOnly: "iconOnly", sidenav: "sidenav" }, {}, []);
export { SidemenuComponentNgFactory as SidemenuComponentNgFactory };
