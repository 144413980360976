var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpHeaders } from '@angular/common/http';
export var API_URL = "./api/"; //url di chiamata al servizio API Controller (per default l'url è impostato in localhost)
export var HttpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'tokenPFX': 'jwt-0a2c227e-c1ce-4dbd-9d7a-0c0f1dcdf034' //TEST
        'tokenPFX': 'jwt-0b3ffe37-a34b-gh4r-9u4r-0def14r3ecd4' //PROD
    })
};
export var Action;
(function (Action) {
    Action["None"] = "None";
    Action["Create"] = "Create";
    Action["Read"] = "Read";
    Action["Update"] = "Update";
    Action["Delete"] = "Delete";
    Action["Count"] = "Count";
    Action["Load"] = "Load";
    Action["Get"] = "Get";
    Action["Find"] = "Find";
    Action["Login"] = "Login";
    Action["Logout"] = "Logout";
    Action["Authentication"] = "Authentication";
    Action["Save"] = "Save";
})(Action || (Action = {}));
var BaseDataService = /** @class */ (function () {
    function BaseDataService(http) {
        this.http = http;
    }
    BaseDataService.prototype.Call = function (controller, action, request) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(API_URL + controller + "/" + action, request).toPromise()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.AsyncGet = function (controller, action) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(API_URL + controller + "/" + action, HttpOptions).toPromise()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.AsyncPost = function (controller, action, request) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(API_URL + controller + "/" + action, request, HttpOptions).toPromise()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.Save = function (controller, dto, properties) {
        if (properties === void 0) { properties = null; }
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {};
                        request.Dto = dto;
                        request.Properties = properties;
                        return [4 /*yield*/, this.AsyncPost(controller, Action.Save, request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.Delete = function (controller, dto) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {};
                        request.Dto = dto;
                        return [4 /*yield*/, this.AsyncPost(controller, Action.Delete, request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.Get = function (controller, search, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {};
                        request.Search = search;
                        request.Filter = filter;
                        return [4 /*yield*/, this.AsyncPost(controller, Action.Get, request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.Read = function (controller, search, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {};
                        request.Search = search;
                        request.Filter = filter;
                        return [4 /*yield*/, this.AsyncPost(controller, Action.Read, request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.Load = function (controller, paging, sortings, search, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {};
                        request.Paging = paging;
                        request.Sortings = sortings;
                        request.Search = search;
                        request.Filter = filter;
                        return [4 /*yield*/, this.AsyncPost(controller, Action.Load, request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    BaseDataService.prototype.Count = function (controller, search, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var count, request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        count = 0;
                        request = {};
                        request.Search = search;
                        request.Filter = filter;
                        return [4 /*yield*/, this.AsyncPost(controller, Action.Count, request)];
                    case 1:
                        response = _a.sent();
                        if (response != null)
                            count = response.Count;
                        return [2 /*return*/, count];
                }
            });
        });
    };
    return BaseDataService;
}());
export { BaseDataService };
