import { ViewMode } from '../base/BaseView';
import { NavigatorService } from '../services/navigator.service';
var ViewContainerComponent = /** @class */ (function () {
    function ViewContainerComponent(navigatorService) {
        this.navigatorService = navigatorService;
        this.Editing = true;
        this.Icon = "";
        this.SwitchMode = false;
        this.GoBackVisible = true;
        this.ViewMode = ViewMode;
    }
    Object.defineProperty(ViewContainerComponent.prototype, "View", {
        get: function () {
            return this.view;
        },
        set: function (view) {
            this.view = view;
            this.SetViewMode(view);
        },
        enumerable: true,
        configurable: true
    });
    ViewContainerComponent.prototype.Add = function () {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.Add();
    };
    ViewContainerComponent.prototype.Refresh = function () {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.Refresh();
    };
    ViewContainerComponent.prototype.SearchChange = function (search) {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.Searching(search);
    };
    ViewContainerComponent.prototype.GoBack = function () {
        this.navigatorService.GoBack();
    };
    ViewContainerComponent.prototype.SetViewMode = function (view) {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.View = view;
    };
    return ViewContainerComponent;
}());
export { ViewContainerComponent };
