import { OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
var SidemenuItemComponent = /** @class */ (function () {
    function SidemenuItemComponent(navigatorService) {
        this.navigatorService = navigatorService;
        this.secondaryMenu = false;
    }
    SidemenuItemComponent.prototype.ngOnInit = function () {
    };
    SidemenuItemComponent.prototype.HasChildMenu = function () {
        var hasChildMenu = (this.menu != null && this.menu.SubMenus != null) ? true : false;
        return hasChildMenu;
    };
    SidemenuItemComponent.prototype.ShowMenu = function (menuSelected) {
        if (this.menus != null) {
            for (var _i = 0, _a = this.menus; _i < _a.length; _i++) {
                var menu = _a[_i];
                if (menu != menuSelected) {
                    menu.Open = false;
                    menu.Active = false;
                    var subMenus = menu.SubMenus;
                    if (subMenus != null) {
                        for (var _b = 0, subMenus_1 = subMenus; _b < subMenus_1.length; _b++) {
                            var subMenu = subMenus_1[_b];
                            subMenu.Open = false;
                            subMenu.Active = false;
                        }
                    }
                }
            }
        }
        menuSelected.Open = !menuSelected.Open;
        menuSelected.Active = true;
        if (this.navigatorService.SmartphoneMode && menuSelected.Link != null && this.navigatorService.Sidenav != null)
            this.navigatorService.Sidenav.toggle();
        if (menuSelected.Link != null)
            this.navigatorService.GoTo(menuSelected.Link);
    };
    return SidemenuItemComponent;
}());
export { SidemenuItemComponent };
