var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { IBaseModel, Sorting, Paging, Direction } from './BaseModel';
import { TableColumnComponent } from '../table-column/table-column.component';
import { EventEmitter, AfterViewInit, QueryList, OnInit } from '@angular/core';
var BaseView = /** @class */ (function () {
    function BaseView(dataService, navigatorService, controller, action, take) {
        var _this = this;
        this.dataService = dataService;
        this.navigatorService = navigatorService;
        this.controller = controller;
        this.action = action;
        this.take = take;
        this.TakeMax = 1000;
        if (take == null) { //todo: view without paging - da aggiustare
            take = this.TakeMax;
            this.navigatorService.Paging = new Paging(take);
        }
        this.Controller = controller;
        this.Action = action;
        this.Paging = this.navigatorService.Paging || new Paging(take);
        this.Sortings = this.navigatorService.Sortings || new Array();
        this.Filter = this.navigatorService.Filter;
        this.PagingMode = (take != null && take < this.TakeMax ? PagingMode.SkipTake : PagingMode.Filtering);
        this.ModelChange = new EventEmitter();
        this.PreloadData(); //preload data (only for SkipTake paging mode)
        this.navigatorService.IsAuthenticated().then(function (authenticated) {
            if (!authenticated)
                _this.navigatorService.GoTo("/login");
        });
    }
    Object.defineProperty(BaseView.prototype, "Model", {
        get: function () {
            return this.model;
        },
        set: function (model) {
            this.model = model;
            this.ModelChange.emit(model);
        },
        enumerable: true,
        configurable: true
    });
    BaseView.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            var columns = _this.Columns;
            var sortings = _this.Sortings;
            if (sortings != null && columns != null) {
                for (var _i = 0, sortings_1 = sortings; _i < sortings_1.length; _i++) {
                    var sorting = sortings_1[_i];
                    var name = sorting.Name;
                    var direction = sorting.Direction;
                    var column = columns.find(function (q) { return q.Name == name; });
                    if (column != null)
                        column.Direction = direction;
                }
            }
        });
    };
    BaseView.prototype.ngOnInit = function () {
    };
    BaseView.prototype.PreloadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pagingMode, paging, sortings, filter, search, request, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        pagingMode = this.PagingMode;
                        if (!(pagingMode == PagingMode.SkipTake)) return [3 /*break*/, 4];
                        paging = this.Paging;
                        sortings = this.Sortings;
                        filter = this.Filter;
                        if (!(this.Action == "StatoPagamenti")) return [3 /*break*/, 2];
                        search = this.Search;
                        request = {};
                        request.Paging = paging;
                        request.Sortings = sortings;
                        request.Search = search;
                        request.Filter = filter;
                        _a = this;
                        return [4 /*yield*/, this.dataService.AsyncPost(this.Controller, this.Action, request)];
                    case 1:
                        _a.Model = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.Load(paging, sortings, null, filter)];
                    case 3:
                        _b.Model = _c.sent();
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BaseView.prototype.Refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var paging, sortings, filter, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        paging = this.Paging;
                        sortings = this.Sortings;
                        filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;
                        _a = this;
                        return [4 /*yield*/, this.Load(paging, sortings, null, filter)];
                    case 1:
                        _a.Model = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BaseView.prototype.Add = function () {
        var action = this.Action;
        if (action != null) {
            this.navigatorService.GoTo(action, { Id: 0 }, this.Filter);
        }
    };
    BaseView.prototype.Edit = function (dto) {
        var action = this.Action;
        if (action != null) {
            this.navigatorService.GoTo(action, dto, this.Filter);
        }
    };
    BaseView.prototype.Show = function (action, filter) {
        if (action != null) {
            this.navigatorService.GoTo(action, null, filter);
        }
    };
    BaseView.prototype.Load = function (paging, sortings, search, filter) {
        var search;
        return __awaiter(this, void 0, void 0, function () {
            var controller, request, model, model;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        controller = this.Controller;
                        if (!(controller != null)) return [3 /*break*/, 5];
                        this.navigatorService.Start();
                        if (!(this.Action == "StatoPagamenti")) return [3 /*break*/, 2];
                        search = this.Search;
                        request = {};
                        request.Paging = paging;
                        request.Sortings = sortings;
                        request.Search = search;
                        request.Filter = filter;
                        return [4 /*yield*/, this.dataService.AsyncPost(this.Controller, this.Action, request)];
                    case 1:
                        model = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dataService.Load(controller, paging, sortings, search, filter)];
                    case 3:
                        model = _a.sent();
                        _a.label = 4;
                    case 4:
                        this.navigatorService.Stop();
                        return [2 /*return*/, model];
                    case 5: return [2 /*return*/, null];
                }
            });
        });
    };
    BaseView.prototype.Get = function (search, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var controller, model;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        controller = this.Controller;
                        if (!(controller != null)) return [3 /*break*/, 2];
                        this.navigatorService.Start();
                        return [4 /*yield*/, this.dataService.Get(controller, search, filter)];
                    case 1:
                        model = _a.sent();
                        this.navigatorService.Stop();
                        return [2 /*return*/, model];
                    case 2: return [2 /*return*/, null];
                }
            });
        });
    };
    BaseView.prototype.LoadData = function (paging, sortings, search, filter) {
        return __awaiter(this, void 0, void 0, function () {
            var pagingMode, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        pagingMode = this.PagingMode;
                        if (!(pagingMode == PagingMode.SkipTake)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.Load(paging, sortings, search, filter)];
                    case 1:
                        _a.Model = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.Get(search, filter)];
                    case 3:
                        _b.Model = _c.sent();
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BaseView.prototype.Searching = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            var paging, sortings, filter;
            return __generator(this, function (_a) {
                paging = this.Paging;
                sortings = this.Sortings;
                filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;
                this.Search = search;
                this.LoadData(paging, sortings, search, filter);
                return [2 /*return*/];
            });
        });
    };
    BaseView.prototype.Filtering = function (filter) {
        return __awaiter(this, void 0, void 0, function () {
            var paging, sortings, search;
            return __generator(this, function (_a) {
                paging = this.Paging;
                sortings = this.Sortings;
                search = this.Filter != null ? this.Filter : this.navigatorService.Filter;
                this.Filter = filter;
                this.LoadData(paging, sortings, search, filter);
                return [2 /*return*/];
            });
        });
    };
    BaseView.prototype.PagingChange = function (paging) {
        return __awaiter(this, void 0, void 0, function () {
            var sortings, search, filter;
            return __generator(this, function (_a) {
                if (paging != null) {
                    sortings = this.Sortings;
                    search = this.Search;
                    filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;
                    this.Paging = paging;
                    this.LoadData(paging, sortings, search, filter);
                }
                return [2 /*return*/];
            });
        });
    };
    BaseView.prototype.SortingChange = function (sorting) {
        return __awaiter(this, void 0, void 0, function () {
            var direction, paging, sortings, search, filter;
            return __generator(this, function (_a) {
                if (sorting != null) {
                    //todo: limitazione ad 1 solo sorting
                    this.Sortings = new Array();
                    direction = sorting.Direction;
                    if (direction == Direction.None)
                        this.RemoveSorting(sorting);
                    else
                        this.AddSorting(sorting);
                    paging = this.Paging;
                    sortings = this.Sortings;
                    search = this.Search;
                    filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;
                    this.LoadData(paging, sortings, search, filter);
                    this.navigatorService.Sortings = sortings;
                }
                return [2 /*return*/];
            });
        });
    };
    BaseView.prototype.FilteringChange = function (filter) {
        return __awaiter(this, void 0, void 0, function () {
            var paging, sortings, search;
            return __generator(this, function (_a) {
                paging = this.Paging;
                sortings = this.Sortings;
                search = this.Search;
                this.LoadData(paging, sortings, search, filter);
                return [2 /*return*/];
            });
        });
    };
    BaseView.prototype.AddSorting = function (sorting) {
        if (sorting != null) {
            var sortings = this.Sortings;
            if (sortings != null) {
                var sortingExist = sortings.find(function (q) { return q.Name == sorting.Name; });
                if (sortingExist != null)
                    sortingExist.Direction = sorting.Direction;
                else
                    this.Sortings.push(sorting);
            }
        }
    };
    BaseView.prototype.RemoveSorting = function (sorting) {
        if (sorting != null) {
            var sortings = this.Sortings;
            if (sortings != null)
                this.Sortings = sortings.filter(function (q) { return q.Name != sorting.Name; });
        }
    };
    return BaseView;
}());
export { BaseView };
export var PagingMode;
(function (PagingMode) {
    PagingMode[PagingMode["SkipTake"] = 0] = "SkipTake";
    PagingMode[PagingMode["Filtering"] = 1] = "Filtering";
})(PagingMode || (PagingMode = {}));
export var ViewMode;
(function (ViewMode) {
    ViewMode["Table"] = "Table";
    ViewMode["Card"] = "Card";
})(ViewMode || (ViewMode = {}));
