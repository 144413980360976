import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';


@Component({
  selector: 'cdk-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public Today: number = Date.now();

  constructor(public navigatorService: NavigatorService) {
    this.SetTimeOfDay();
  }

  ngOnInit() {
  }

  private SetTimeOfDay() {
    setInterval(() => {
      this.Today = Date.now();
    }, 59000);
  }

}
