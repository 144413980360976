var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this.Timeout = 10000; //timeout in secondi per lo spinner
        this.Running = false;
    }
    SpinnerService.prototype.Show = function () {
        var _this = this;
        this.Running = true;
        setTimeout(function () {
            if (_this.Running)
                _this.Hide();
        }, this.Timeout);
    };
    SpinnerService.prototype.Hide = function () {
        this.Running = false;
    };
    return SpinnerService;
}());
export { SpinnerService };
//export class Runner {
//    public Time: Date;
//    constructor(time: Date) {
//        this.Time = time;
//    }
//}
