import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { NavigatorService } from '../services/navigator.service';

@Component({
  selector: 'cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isOpen: boolean = false;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }


  constructor(private elementRef: ElementRef, public navigatorService: NavigatorService, private dataService: DataService) {
  }


  ngOnInit() {
  }

  Logout() {
    //avvio procedura di logout utente
    this.dataService.Logout().then(model => {
      this.navigatorService.GoTo("/login");
    });
  }
}
