var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './core/base/BaseDataService';
export var Controller;
(function (Controller) {
    Controller["Dashboard"] = "Dashboard";
    Controller["Statistic"] = "Statistic";
    Controller["Settings"] = "Settings";
    Controller["Storage"] = "Storage";
    Controller["WhatsNew"] = "WhatsNew";
    Controller["Mandante"] = "Mandante";
    Controller["Payment"] = "Payment";
    Controller["Pratica"] = "Pratica";
    Controller["Prodotto"] = "Prodotto";
    Controller["Debitore"] = "Debitore";
    Controller["Pagamento"] = "Pagamento";
    Controller["Documento"] = "Documento";
    Controller["SistemaPagamento"] = "SistemaPagamento";
    Controller["SistemaNotifica"] = "SistemaNotifica";
    Controller["Comunicazione"] = "Comunicazione";
    Controller["PosizioneDebitoria"] = "PosizioneDebitoria";
    Controller["ProviderNotifica"] = "ProviderNotifica";
    Controller["TemplateNotifica"] = "TemplateNotifica";
    Controller["ModelloComunicazione"] = "ModelloComunicazione";
    Controller["Enumeration"] = "Enumeration";
    Controller["Security"] = "Security";
    Controller["Notification"] = "Notification";
    Controller["Report"] = "Report";
    Controller["Faq"] = "Faq";
    Controller["Identificazione"] = "Identificazione";
})(Controller || (Controller = {}));
export var Action;
(function (Action) {
    Action["ReadToken"] = "ReadToken";
    Action["Verify"] = "Verify";
    Action["Execute"] = "Execute";
    Action["Build"] = "Build";
    Action["Clone"] = "Clone";
    Action["Search"] = "Search";
    Action["UpdateStato"] = "UpdateStato";
    Action["SendPagamentoEffettuato"] = "SendPagamentoEffettuato";
    Action["SendPagamentoMandante"] = "SendPagamentoMandante";
    Action["SendPagamentoDilazionato"] = "SendPagamentoDilazionato";
    Action["SendPagamentoParziale"] = "SendPagamentoParziale";
    Action["SendPagamentoPDR"] = "SendPagamentoPDR";
    Action["SendPagamentoSaldoStralcio"] = "SendPagamentoSaldoStralcio";
    Action["SendPagamentoSaldoStralcioRateale"] = "SendPagamentoSaldoStralcioRateale";
    Action["SendDocumento"] = "SendDocumento";
    Action["LoadProviderPagamento"] = "LoadProviderPagamento";
    Action["SendRifiutoPagamento"] = "SendRifiutoPagamento";
    Action["LoadRifiutoPagamento"] = "LoadRifiutoPagamento";
    Action["SendCodiceSicurezza"] = "SendCodiceSicurezza";
    Action["SendNotification"] = "SendNotification";
})(Action || (Action = {}));
var DataService = /** @class */ (function (_super) {
    __extends(DataService, _super);
    function DataService(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    return DataService;
}(BaseDataService));
export { DataService };
