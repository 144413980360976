import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { NavigatorService } from '../services/navigator.service';

@Component({
  selector: 'cdk-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit {

  @Input() sidenav;
  @Input() sidebar;
  @Input() drawer;
  @Input() matDrawerShow;

  searchOpen: boolean = false;

  constructor(public navigatorService: NavigatorService, private dataService: DataService) {

  }

  ngOnInit() {
  }

  Logout() {
    //avvio procedura di logout utente
    this.navigatorService.Start();
    this.dataService.Logout().then(model => {
      this.navigatorService.GoTo("/login");
      this.navigatorService.Stop();
    });
  }

  SetSidebarTab(tabIndex) {
    this.sidebar.toggle();
    this.navigatorService.SidebarTabIndex = tabIndex;
  }

  ToggleSidenav() {
    this.sidenav.toggle();
    if (this.matDrawerShow) {
      this.drawer.toggle();
    }
    this.navigatorService.Sidenav = this.sidenav;
  }

}
