/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../edit-search/edit-search.component.ngfactory";
import * as i13 from "../edit-search/edit-search.component";
import * as i14 from "@angular/common";
import * as i15 from "./view-container.component";
import * as i16 from "../services/navigator.service";
var styles_ViewContainerComponent = [i0.styles];
var RenderType_ViewContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewContainerComponent, data: {} });
export { RenderType_ViewContainerComponent as RenderType_ViewContainerComponent };
function View_ViewContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "td", [["align", "right"], ["style", "width:48px"], ["valign", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "edit-button-icon"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SetViewMode(_co.ViewMode.Card) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "i", [["class", "material-icons icon-button-add"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["apps"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ViewContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "td", [["align", "right"], ["style", "width:48px"], ["valign", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "edit-button-icon"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SetViewMode(_co.ViewMode.Table) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "i", [["class", "material-icons icon-button-add"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["view_headline"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ViewContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "td", [["align", "right"], ["style", "width:48px"], ["valign", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "edit-button-add"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 16777216, null, 0, 2, "i", [["class", "material-icons icon-button-add"], ["matTooltip", "Aggiungi"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["add"]))], function (_ck, _v) { var currVal_2 = "Aggiungi"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ViewContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "td", [["align", "right"], ["style", "width:48px"], ["valign", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 4, "button", [["class", "edit-button-back"], ["mat-icon-button", ""], ["matTooltip", "Torna indietro"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.GoBack() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "i", [["class", "material-icons icon-button-back"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["arrow_back"]))], function (_ck, _v) { var currVal_2 = "Torna indietro"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ViewContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 33, "div", [["class", "view-container"], ["title", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "view-background container-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 31, "div", [["class", "container container-padding container-fuild"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "view-header-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 25, "table", [["style", "width:100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 24, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 23, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [["align", "center"], ["style", "padding-top:8px;width:32px"], ["valign", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "i", [["class", "material-icons icon-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "td", [["align", "left"], ["style", "padding-top:8px;"], ["valign", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "edit-breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "td", [["align", "right"], ["valign", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-edit-search", [["class", "edit-search"]], null, [[null, "SearchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("SearchChange" === en)) {
        var pd_0 = (_co.SearchChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_EditSearchComponent_0, i12.RenderType_EditSearchComponent)), i1.ɵdid(15, 49152, null, 0, i13.EditSearchComponent, [], null, { SearchChange: "SearchChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewContainerComponent_1)), i1.ɵdid(17, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewContainerComponent_2)), i1.ɵdid(19, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewContainerComponent_3)), i1.ɵdid(21, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 5, "td", [["align", "right"], ["style", "width:48px"], ["valign", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "button", [["class", "edit-button-refresh"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(24, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(25, 16777216, null, 0, 2, "i", [["class", "material-icons icon-button-refresh"], ["matTooltip", "Aggiorna"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 26)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 26)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 147456, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["refresh"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewContainerComponent_4)), i1.ɵdid(29, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "div", [["class", "col-md-12"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.SwitchMode; _ck(_v, 17, 0, currVal_2); var currVal_3 = _co.SwitchMode; _ck(_v, 19, 0, currVal_3); var currVal_4 = _co.Editing; _ck(_v, 21, 0, currVal_4); var currVal_7 = "Aggiorna"; _ck(_v, 26, 0, currVal_7); var currVal_8 = (_co.navigatorService.CanGoBack && _co.GoBackVisible); _ck(_v, 29, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Icon; _ck(_v, 9, 0, currVal_0); var currVal_1 = ((_co.Title == null) ? null : _co.Title.toUpperCase()); _ck(_v, 12, 0, currVal_1); var currVal_5 = (i1.ɵnov(_v, 24).disabled || null); var currVal_6 = (i1.ɵnov(_v, 24)._animationMode === "NoopAnimations"); _ck(_v, 23, 0, currVal_5, currVal_6); }); }
export function View_ViewContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-container", [], null, null, null, View_ViewContainerComponent_0, RenderType_ViewContainerComponent)), i1.ɵdid(1, 49152, null, 0, i15.ViewContainerComponent, [i16.NavigatorService], null, null)], null, null); }
var ViewContainerComponentNgFactory = i1.ɵccf("app-view-container", i15.ViewContainerComponent, View_ViewContainerComponent_Host_0, { Editing: "Editing", Icon: "Icon", SwitchMode: "SwitchMode", Title: "Title", GoBackVisible: "GoBackVisible", ViewComponent: "ViewComponent", View: "View" }, {}, ["*"]);
export { ViewContainerComponentNgFactory as ViewContainerComponentNgFactory };
