<table style="width:100%">
  <tr>
    <td></td>
    <td align="center" valign="middle" style="width:20px;">
      <button mat-icon-button class="edit-search-button" (click)="this.Opened=!this.Opened"><i class="material-icons search-icon">search</i></button>
    </td>
    <td align="left" valign="middle" [ngClass]="this.Opened?'edit-search-expanded':'edit-search-collapsed'">
      <mat-form-field class="edit-form" >
        <input #search matInput placeholder="Cerca" autocomplete="off" matTooltip="Digita una o più chiavi di ricerca distanziate da uno spazio..." (input)="SearchingAsync(search)">
      </mat-form-field>
    </td>
    <td align="center" valign="middle" style="width:20px;" [ngClass]="this.Opened?'edit-search-expanded':'edit-search-collapsed'">
      <mat-spinner *ngIf="this.SearchWaiting" [diameter]="16" class="search-spinner"></mat-spinner>
    </td>
  </tr>
  
</table>

