/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i8 from "@angular/material/divider";
import * as i9 from "@angular/common";
import * as i10 from "./popup.component";
import * as i11 from "@angular/material/dialog";
var styles_PopupComponent = [i0.styles];
var RenderType_PopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
function View_PopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [["align", "right"], ["style", "width:100px;padding-right:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Conferma"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["mat-dialog-title", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i7.View_MatDivider_0, i7.RenderType_MatDivider)), i1.ɵdid(3, 49152, null, 0, i8.MatDivider, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["mat-dialog-content", ""], ["style", "width:500px; height:150px;margin-top:20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "table", [["style", "width:100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "td", [["align", "right"], ["style", "width:100px;padding-right:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Annulla"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_1)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = (_co.ConfirmCallback != null); _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Title; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 3).vertical ? "vertical" : "horizontal"); var currVal_2 = i1.ɵnov(_v, 3).vertical; var currVal_3 = !i1.ɵnov(_v, 3).vertical; var currVal_4 = i1.ɵnov(_v, 3).inset; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.Message; _ck(_v, 6, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_PopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup", [], null, null, null, View_PopupComponent_0, RenderType_PopupComponent)), i1.ɵdid(1, 49152, null, 0, i10.PopupComponent, [i11.MatDialogRef, i11.MAT_DIALOG_DATA], null, null)], null, null); }
var PopupComponentNgFactory = i1.ɵccf("app-popup", i10.PopupComponent, View_PopupComponent_Host_0, {}, {}, []);
export { PopupComponentNgFactory as PopupComponentNgFactory };
