import { Injectable, EventEmitter, Output } from "@angular/core";

declare var OneDrive: OneDrive;

@Injectable()
export class OneDriveService {

  @Output()
  public Selected: EventEmitter<OneDriveItem>;

  constructor() {
    this.Selected = new EventEmitter<OneDriveItem>();
  }

  public Open() {
    var self = this;
    var options: OneDriveOpenOptions = {
      clientId: "db4aab0b-556c-4bd7-9fa5-89733a4a72b7",  // for activation app-id https://apps.dev.microsoft.com
      action: "share",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        //filter: "folder,.png" /* display folder and files with extension '.png' only */
        //filter: "folder,photo"
      },
      success: function (response) {
        if (response != null) {
          var value = response.value;
          if (value != null && value.length >= 1) {
            var file = value[0];
            if (file != null) {
              var item = new OneDriveItem();
              item.Name = file.name;
              item.Url = file.webUrl;
              self.Selected.emit(item);
            }
          }
        }
       
      },
      cancel: function () { },
      error: function (e) {
        console.log(e);
      }
    };

    OneDrive.open(options);
  }

}

export class OneDriveItem {
  public Name: string;
  public Url: string;
  public get Value(): string {
    return JSON.stringify(this);
  }
}

export interface OneDriveResult {
  value: OneDriveFile[];
  webUrl: string | null;
}

export interface OneDriveFile {
  "@microsoft.graph.downloadUrl": string;
  "thumbnails@odata.context": string;
  id: string;
  name: string;
  size: number;
  thumbnails: Thumbnails[];
  webUrl: string;
}

export interface Thumbnails {
  id: string;
  large: Thumbnail;
  medium: Thumbnail;
  small: Thumbnail;
}

export interface Thumbnail {
  height: number;
  width: number;
  url: string;
}

export interface OneDriveOpenOptions {
  clientId: string;
  action: "download" | "share" | "query";
  multiSelect: boolean;
  openInNewWindow: boolean;
  advanced: {
    filter?: string;
  }
  success(result: OneDriveResult): void;
  cancel(): void;
  error(e: any): void;
}

export interface OneDrive {
  open(options: OneDriveOpenOptions);
}

