var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChatbotModel } from '../../models/ChatbotModel';
import { DataService, Controller, Action } from '../../data.service';
import { FaqModel } from '../../models/FaqModel';
var PagamentoChatbotComponent = /** @class */ (function () {
    function PagamentoChatbotComponent(dataService) {
        this.dataService = dataService;
        this.ShowingChatbot = false;
        this.InitView();
        this.ShowBenvenuto();
    }
    PagamentoChatbotComponent.prototype.InitView = function () {
        this.Chatbots = new Array();
    };
    PagamentoChatbotComponent.prototype.AddChatbotMessage = function (chatbot) {
        var _this = this;
        if (chatbot != null) {
            var timeout = chatbot.Direction == "left" ? 2000 : 125;
            setTimeout(function () {
                var chatbots = _this.Chatbots;
                chatbots.push(chatbot);
                _this.Chatbots = chatbots;
            }, timeout);
        }
    };
    PagamentoChatbotComponent.prototype.HideChatbot = function () {
        this.ShowingChatbot = false;
    };
    PagamentoChatbotComponent.prototype.ShowHideChatbot = function () {
        this.ShowingChatbot = !this.ShowingChatbot;
    };
    PagamentoChatbotComponent.prototype.SendMessage = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var request, model, dtos, dto;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ShowMessage("right", message);
                        request = new FaqModel();
                        request.Search = message;
                        return [4 /*yield*/, this.dataService.AsyncPost(Controller.Faq, Action.Search, request)];
                    case 1:
                        model = _a.sent();
                        if (model != null && model.Performed) {
                            dtos = model.Dtos;
                            if (dtos != null && dtos.length >= 1) {
                                dto = dtos[0];
                                this.ShowMessage("left", dto.Risposta);
                            }
                            else
                                this.ShowIncomprensione();
                        }
                        else {
                            //risposta con messaggio di incomprensione!!!
                            this.ShowIncomprensione();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PagamentoChatbotComponent.prototype.ShowBenvenuto = function () {
        var chatbot = new ChatbotModel("left", "Ciao io sono il tuo assistente Chatbot!. Digita pure una domanda attinente al pagamento, cercherò di aiutarti");
        this.AddChatbotMessage(chatbot);
    };
    PagamentoChatbotComponent.prototype.ShowMessage = function (direction, message) {
        var chatbot = new ChatbotModel(direction, message);
        this.AddChatbotMessage(chatbot);
    };
    PagamentoChatbotComponent.prototype.ShowIncomprensione = function () {
        var chatbot = new ChatbotModel("left", "Mi dispiace ma mi occorrono più informazioni, puoi riformulare la domanda?");
        this.AddChatbotMessage(chatbot);
    };
    return PagamentoChatbotComponent;
}());
export { PagamentoChatbotComponent };
