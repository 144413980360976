import { Location } from '@angular/common';
import { Injectable, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import 'rxjs/add/operator/timeout';
import { ContactModel } from '../models/ContactModel';
import { SpinnerService } from './spinner.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PopupComponent, DataDialog } from '../popup/popup.component';
import { UserModel, UserDto } from '../models/UserModel';
import { Paging, Sorting } from '../base/BaseModel';
import { DataService } from './data.service';

@Injectable()
export class NavigatorService implements OnInit {

  public DesktopMode: boolean;
  public TabletMode: boolean;
  public SmartphoneMode: boolean;
  public UrlImage: any;
  public Nickname: string;
  public Menus: any; //todo:-> tipizzare menù applicativo
  public SidebarTabIndex: number;
  public Sidenav: any; //todo:-> tipizzare con controllo
  public Action: string; //azione di navigazione (view/detail)
  public Dto: any; //per passaggio da view --> detail
  public Filter: any; //per filtro dati detail --> view
  public UrlPdfFile: string; //url per visualizzazione pdf document
  public ApplicationVersion: string;
  public ApplicationShortName: string;
  public ApplicationSuffixName: string;
  public ApplicationTitle: string;
  public ApplicationCompany: string;
  public ApplicationContacts: string; //url pagina contatti esterna
  public ApplicationPrivacy: string; //url pagina privacy esterna
  public ApplicationStarted: boolean;
  public Contact: ContactModel;
  public Paging: Paging;
  public Sortings: Array<Sorting>;
  public Breadcrumbs: Array<Breadcrumb>;
  public SecurityWorkflow: boolean = false;
  public UserInfo: UserDto = null;

  //variabili user-data
  public LinkTelefonico: boolean = false;
  public TelefonoAssistenza: string = null;

  public get CanGoBack(): boolean {
    var goBack: boolean = false;
    if (this.Breadcrumbs != null)
      goBack = this.Breadcrumbs.length >= 2;

    return goBack;
  }

  constructor(private dataService: DataService, public router: Router, public spinnerService: SpinnerService, public location: Location, public sanitizer: DomSanitizer, public titleService: Title, public dialog: MatDialog, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
    this.ApplicationStarted = false;
    this.InitNavigationInterceptor();
    this.Breadcrumbs = new Array<Breadcrumb>();
    this.location.subscribe(q => this.GoBackPopState(q)); //intercept --> evento Browser Back
    //this.router.events.subscribe(q => this.InitRefresh(q)); //intercept --> evento Browser Refresh

  }

  ngOnInit() {

  }

  private InitNavigationInterceptor() {
    this.router.events.subscribe((event: Event) => {
      this.NavigationInterceptor(event);
    });
  }

  private NavigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.Start();
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.Stop();
    }
  }

  public async IsAuthenticated(): Promise<boolean> {
    var authenticated = false;
    var model = await this.dataService.GetAuthentication();
    if (model != null && model.Performed) {
      var dto = model.Dto;
      if (dto != null) {
        authenticated = dto.Authenticated;
      }
    }
    return authenticated;
  }

  public InitApplicationParameters() {
    var title = this.ApplicationTitle;
    this.titleService.setTitle(title);
  }

  public SetUserData(model: UserModel) {
    if (model != null) {
      var dto = model.Dto;
      if (dto != null) {
        var image = (dto.Image != null ? "stage/" + dto.Image : "avatars/nouser.png");
        this.UrlImage = this.sanitizer.bypassSecurityTrustStyle("url(../../../assets/images/" + image + ")");
        this.Nickname = dto.Nickname;
      }
    }
  }

  public Start() {
    this.spinnerService.Show();
  }

  public Stop() {
    this.spinnerService.Hide();
  }

  public ShowPdf(urlPdfFile: string) {
    this.UrlPdfFile = urlPdfFile;
    this.GoTo("/container/pdfvisualizer");
  }

  public GoTo(action: string, dto?: any, filter?: any, extras?: NavigationExtras) {
    if (dto == null) { //view --> azzeramento dati di Paging/Sortings
      this.Paging = new Paging();
      this.Sortings = new Array<Sorting>();
    }

    var breadcrumb = new Breadcrumb();
    breadcrumb.Action = action;
    breadcrumb.Dto = dto;
    breadcrumb.Filter = filter;
    this.Breadcrumbs.push(breadcrumb);

    this.Action = action;
    this.Dto = dto;
    this.Filter = filter;
    this.router.navigate([action], extras);
  }

  public GoBack() {
    this.location.back();
  }

  private GoBackPopState(navigation: any) {
    if (navigation != null) {
      var type = navigation.type;
      //todo: da rivedere action==action --> var action = navigation.url;
      if (type == "popstate") {
        var breadcrumbs = this.Breadcrumbs;
        if (breadcrumbs != null) {
          breadcrumbs.pop();

          var count = breadcrumbs.length;
          if (count >= 1) {
            var lastBreadcrumb = breadcrumbs[count - 1];
            if (lastBreadcrumb != null) {
              this.Dto = lastBreadcrumb.Dto;
              this.Filter = lastBreadcrumb.Filter;
            }
          }
        }
      }
    }
  }

  //todo: da rivedere
  private InitRefresh(event: any) {
    if (event != null && event instanceof NavigationStart) {
      if (!this.router.navigated) {
        if (!this.ApplicationStarted) {
          console.log("start");
          this.ApplicationStarted = true;
        } else {
          console.log("refresh");
        }
      }
    }
  }

  public ShowPopup(title: string, message: string, confirmCallback: () => Promise<void>) {
    var dataDialog = new DataDialog();
    dataDialog.Title = title;
    dataDialog.Message = message;
    dataDialog.ConfirmCallback = confirmCallback;
    this.dialog.open(PopupComponent, { data: dataDialog });
  }

  public ShowMessage(message: string, action?: string) {
    this.snackBar.open(message, action, { duration: 2000, });
  }

  public GetQueryParameter(parameter: string): string {
    if (this.activatedRoute != null) {
      var snapshot = this.activatedRoute.snapshot;
      if (snapshot != null) {
        var queryParameters = snapshot.queryParamMap;
        if (queryParameters != null) {
          var value = queryParameters.get(parameter);
          return value;
        }
      }
    }
    return null;
  }
}


export class Breadcrumb {
  public Action: string;
  public Dto: any;
  public Filter: any;
}
