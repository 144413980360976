import { EventEmitter } from '@angular/core';
var EditSearchComponent = /** @class */ (function () {
    function EditSearchComponent() {
        this.Timeout = 500; // 500ms timeout di default
        this.SearchWaiting = false;
        this.Opened = false;
        this.SearchChange = new EventEmitter();
    }
    EditSearchComponent.prototype.SearchingAsync = function (input) {
        var _this = this;
        if (input != null) {
            this.SearchWaiting = true;
            var search = input.value;
            if (this.Timer != null)
                clearTimeout(this.Timer);
            this.Timer = setTimeout(function () {
                _this.SearchWaiting = false;
                _this.Searching(search);
            }, this.Timeout);
        }
    };
    EditSearchComponent.prototype.Searching = function (search) {
        this.SearchChange.emit(search);
    };
    return EditSearchComponent;
}());
export { EditSearchComponent };
