
<perfect-scrollbar style="height: calc(100% - 33px);" class="sidemenu-background"  >
    <div fxLayout="column">
        <div *ngIf="!iconOnly" fxLayoutAlign="space-around center" [style.margin]="'10px 0px'" >
          <div class="image-rounded">
            <div [style.background-image]="this.navigatorService.UrlImage" style="width:100%; height:100%; background-repeat: no-repeat no-repeat; background-size:cover "></div>
          </div>
        </div>
        <div *ngIf="iconOnly" style="height: 100px;" fxLayoutAlign="space-around center">
          <div class="image-rounded-mini">
            <div [style.background-image]="this.navigatorService.UrlImage" style="width:100%; height:100%; background-repeat: no-repeat no-repeat; background-size:cover "></div>
          </div>
        </div>
        <cdk-sidemenu-item *ngFor="let menu of this.navigatorService?.Menus" [menus]="this.navigatorService?.Menus" [menu]="menu" [iconOnly]="iconOnly" [sidenav]="sidenav"> </cdk-sidemenu-item>
    </div>
    
</perfect-scrollbar>
