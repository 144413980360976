var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel, BaseEntity } from "../core/base/BaseModel";
var FaqModel = /** @class */ (function (_super) {
    __extends(FaqModel, _super);
    function FaqModel() {
        return _super.call(this) || this;
    }
    return FaqModel;
}(BaseModel));
export { FaqModel };
var FaqEntity = /** @class */ (function (_super) {
    __extends(FaqEntity, _super);
    function FaqEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FaqEntity;
}(BaseEntity));
export { FaqEntity };
var FaqDto = /** @class */ (function (_super) {
    __extends(FaqDto, _super);
    function FaqDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FaqDto;
}(FaqEntity));
export { FaqDto };
var FaqFilter = /** @class */ (function (_super) {
    __extends(FaqFilter, _super);
    function FaqFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FaqFilter;
}(FaqDto));
export { FaqFilter };
