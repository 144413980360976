<div>
  <table style="width:100%">
    <tr>
      <td align="{{this.Direction}}">
        <table>
          <tr>
            <td style="width:16px">
              <img src="./assets/images/tools/{{this.Image}}" style="width:16px;" />
            </td>
            <td>
              <span style="font-size:10px; color:gray">
                <span style="font-weight:bold; margin-right:5px;">@{{this.Username}}</span>
                <span *ngIf="this.Writing">sta scrivendo...</span>
                <span *ngIf="!this.Writing">{{this.Timestamp | date:'dd/MM/yyyy HH:mm'}}</span>
              </span>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td align="{{this.Direction}}">
        <div *ngIf="!this.Writing" [ngClass]="this.Direction=='left' ? 'chatbot-message-left' : 'chatbot-message-right'">
          <span style="color:white; font-size:11px; ">{{this.Message}}</span>
        </div>
      </td>
    </tr>
  </table>
</div>
