<div class="container-external">
  <perfect-scrollbar style="height: calc(100% - 0px);">
    <div style="padding:20px;">
      <router-outlet></router-outlet>
    </div>
  </perfect-scrollbar>
</div>

<app-pagamento-chatbot></app-pagamento-chatbot>
<!--<app-pagamento-assistenza></app-pagamento-assistenza>-->

<div class="container-external-bottom">
  <table style="width:100%">
    <tr>
      <td align="right" valign="middle">
        <span class="external-text-bottom app-text-gray">{{this.navigatorService.ApplicationShortName}} {{this.navigatorService.ApplicationSuffixName}} | <span>&copy; 2019 {{this.navigatorService.ApplicationCompany}}</span> | <a class="app-link" href="{{this.navigatorService.ApplicationContacts}}" target="_blank">Contatti</a> | <a class="app-link" href="/external/pagamento/faq">Area faq</a></span>
      </td>
    </tr>
  </table>
</div>





