import { OnInit, AfterViewInit } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
var PagamentoChatbotMessageComponent = /** @class */ (function () {
    function PagamentoChatbotMessageComponent() {
        this.Writing = true;
        this.Timestamp = new Date();
    }
    Object.defineProperty(PagamentoChatbotMessageComponent.prototype, "Image", {
        get: function () {
            var direction = this.Direction;
            var image = direction == "left" ? "chatbot_icon.png" : "chatbot_user.png";
            return image;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagamentoChatbotMessageComponent.prototype, "Username", {
        get: function () {
            var direction = this.Direction;
            var username = direction == "left" ? "Chatbot" : "Tu";
            return username;
        },
        enumerable: true,
        configurable: true
    });
    PagamentoChatbotMessageComponent.prototype.ngAfterViewInit = function () {
    };
    PagamentoChatbotMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        var timeout = (this.Direction == "left" ? 2000 : 125);
        setTimeout(function () {
            _this.Writing = false;
            //scroll auto
            if (_this.Scrollbar != null) {
                setTimeout(function () {
                    _this.Scrollbar.directiveRef.scrollToBottom(0, 250);
                }, 500);
            }
        }, timeout);
    };
    return PagamentoChatbotMessageComponent;
}());
export { PagamentoChatbotMessageComponent };
