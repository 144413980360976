import { MatDialogRef } from '@angular/material';
var PopupComponent = /** @class */ (function () {
    function PopupComponent(dialog, data) {
        this.dialog = dialog;
        this.data = data;
        if (data != null) {
            this.Title = data.Title;
            this.Message = data.Message;
            this.ConfirmCallback = data.ConfirmCallback;
        }
    }
    PopupComponent.prototype.Cancel = function () {
        this.dialog.close();
    };
    PopupComponent.prototype.Confirm = function () {
        if (this.ConfirmCallback != null)
            this.ConfirmCallback();
        this.dialog.close();
    };
    return PopupComponent;
}());
export { PopupComponent };
var DataDialog = /** @class */ (function () {
    function DataDialog() {
    }
    return DataDialog;
}());
export { DataDialog };
