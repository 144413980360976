import { EventModel } from '../models/EventModel';
import { DatePipe } from '@angular/common';
import { ValidationService } from './validation.service';
var CalendarService = /** @class */ (function () {
    function CalendarService(datePipe, validationService) {
        this.datePipe = datePipe;
        this.validationService = validationService;
        this.ViewMode = ViewMode.Month;
        this.Date = new Date();
    }
    CalendarService.prototype.GetEvents = function (dtos, fieldId, fieldName, fieldStart, fieldEnd, fieldAllDay, fieldColor) {
        if (dtos != null) {
            var events = new Array();
            for (var _i = 0, dtos_1 = dtos; _i < dtos_1.length; _i++) {
                var dto = dtos_1[_i];
                var event = this.GetEvent(dto, fieldId, fieldName, fieldStart, fieldEnd, fieldAllDay, fieldColor);
                if (event != null)
                    events.push(event);
            }
            return events;
        }
        return null;
    };
    CalendarService.prototype.GetEvent = function (dto, fieldId, fieldName, fieldStart, fieldEnd, fieldAllDay, fieldColor) {
        if (dto != null) {
            var allDay = dto[fieldAllDay];
            var event = new EventModel();
            event.id = dto[fieldId].toString();
            event.title = dto[fieldName];
            if (allDay)
                event.date = this.GetDateUTC(dto[fieldStart], true);
            else {
                event.start = this.GetDateTimeUTC(dto[fieldStart]);
                event.end = this.GetDateTimeUTC(dto[fieldEnd]);
            }
            event.backgroundColor = dto[fieldColor] || "blue";
            event.borderColor = dto[fieldColor] || "blue";
            event.textColor = "white";
            event.Dto = dto;
            return event;
        }
        return null;
    };
    CalendarService.prototype.GetDateUTC = function (date, withoutTime) {
        if (date != null && this.validationService.IsValidDate(date)) {
            var format = 'yyyy-MM-ddT00:00:00';
            if (withoutTime == true)
                format = 'yyyy-MM-dd';
            var dateMVC = this.datePipe.transform(date, format);
            return dateMVC;
        }
        return null;
    };
    CalendarService.prototype.GetDateTimeUTC = function (dateTime) {
        if (dateTime != null && this.validationService.IsValidDate(dateTime)) {
            var timeMVC = this.datePipe.transform(dateTime, 'yyyy-MM-ddTHH:mm:ss');
            return timeMVC;
        }
        return null;
    };
    CalendarService.prototype.GetDateAndTimeUTC = function (date, time) {
        if (date != null) {
            var dateTime = new Date(date);
            if (time != null) {
                var timeSpan = this.GetTimeSpanFromDateTime(time);
                if (timeSpan != null) {
                    dateTime.setHours(timeSpan.Hours, timeSpan.Minutes, timeSpan.Seconds);
                }
            }
            var dateTimeMVC = this.datePipe.transform(dateTime, 'yyyy-MM-ddTHH:mm:ss');
            return dateTimeMVC;
        }
        return null;
    };
    CalendarService.prototype.GetTimeSpanFromDateTime = function (dateTime) {
        if (dateTime != null) {
            var date = new Date(dateTime);
            var timeSpan = new TimeSpan();
            timeSpan.Hours = date.getHours();
            timeSpan.Minutes = date.getMinutes();
            timeSpan.Seconds = date.getSeconds();
            return timeSpan;
        }
    };
    CalendarService.prototype.GetTimeSpanFromTimeFormat = function (time, format) {
        if (time != null && format != null) {
            var timeSpan = new TimeSpan();
            if (format == Format.AMPM) {
                var splits = time.split(" ");
                if (splits.length >= 2) {
                    var timeAMPM = splits[0];
                    var meridian = splits[1];
                    timeSpan = this.GetTimeSpanFromTime(timeAMPM);
                    if (meridian.toUpperCase() == Meridian.PM) {
                        timeSpan.Hours += 12;
                    }
                }
            }
            else {
                timeSpan = this.GetTimeSpanFromTime(time);
            }
            return timeSpan;
        }
        return null;
    };
    CalendarService.prototype.GetTimeSpanFromTime = function (time) {
        if (time != null) {
            var splits = time.split(":");
            var hours = 0;
            var minutes = 0;
            var seconds = 0;
            if (splits.length >= 1) {
                hours = parseInt(splits[0]);
                if (splits.length >= 2)
                    minutes = parseInt(splits[1]);
                if (splits.length >= 3)
                    seconds = parseInt(splits[2]);
                var timeSpan = new TimeSpan();
                timeSpan.Hours = hours;
                timeSpan.Minutes = minutes;
                timeSpan.Seconds = seconds;
                return timeSpan;
            }
        }
        return null;
    };
    CalendarService.prototype.GetTimeFromDateTime = function (dateTime, format) {
        if (dateTime != null && format != null) {
            var timeSpan = this.GetTimeSpanFromDateTime(dateTime);
            if (timeSpan != null) {
                var hours = timeSpan.Hours;
                var minutes = timeSpan.Minutes;
                var meridian = (format == Format.AMPM ? (hours <= 12 ? "AM" : "PM") : "");
                if (format == Format.AMPM && hours > 12)
                    hours -= 12;
                var hoursFormat = (hours < 10 ? "0" : "") + hours.toString();
                var minutesFormat = (minutes < 10 ? "0" : "") + minutes.toString();
                var time = hoursFormat + ":" + minutesFormat + " " + meridian;
                return time;
            }
        }
        return null;
    };
    CalendarService.prototype.GetDateTimeFromTimeFormat = function (time, format) {
        if (time != null && time.length >= 1 && format != null) {
            var timeSpan = this.GetTimeSpanFromTimeFormat(time, format);
            if (timeSpan != null) {
                var dateTime = new Date();
                dateTime.setHours(timeSpan.Hours, timeSpan.Minutes, timeSpan.Seconds);
                return dateTime;
            }
        }
        return null;
    };
    CalendarService.prototype.GetDaysInMonth = function (year, month) {
        if (year > 0 && month >= 0) {
            var daysInMonth = new Date(year, month + 1, 0).getDate();
            return daysInMonth;
        }
        return null;
    };
    CalendarService.prototype.AddDays = function (date, days) {
        if (date != null && days != null) {
            var newDate = new Date(date);
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        }
        return null;
    };
    CalendarService.prototype.GetDateFormat = function (date, format) {
        if (date != null && format != null) {
            var dateFormat = this.datePipe.transform(date, format);
            return dateFormat;
        }
        return null;
    };
    return CalendarService;
}());
export { CalendarService };
var TimeSpan = /** @class */ (function () {
    function TimeSpan() {
    }
    return TimeSpan;
}());
export { TimeSpan };
export var Format;
(function (Format) {
    Format["AMPM"] = "12";
    Format["H24"] = "24";
})(Format || (Format = {}));
export var Meridian;
(function (Meridian) {
    Meridian["AM"] = "AM";
    Meridian["PM"] = "PM";
})(Meridian || (Meridian = {}));
var DateRange = /** @class */ (function () {
    function DateRange() {
    }
    return DateRange;
}());
export { DateRange };
export var ViewMode;
(function (ViewMode) {
    ViewMode["Month"] = "dayGridMonth";
    ViewMode["Week"] = "timeGridWeek";
    ViewMode["Day"] = "timeGridDay";
})(ViewMode || (ViewMode = {}));
