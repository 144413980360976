<h1 mat-dialog-title>{{this.Title}}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content style="width:500px; height:150px;margin-top:20px;">
  <p>{{this.Message}}</p>
</div>
<div mat-dialog-actions>
  <table style="width:100%">
    <tr>
      <td></td>
      <td align="right" style="width:100px;padding-right:10px;">
        <button mat-button (click)="Cancel()">Annulla</button>
      </td>
      <td *ngIf="this.ConfirmCallback!=null"  align="right" style="width:100px;padding-right:10px;">
        <button mat-button color="primary" cdkFocusInitial (click)="Confirm()">Conferma</button>
      </td>
    </tr>
  </table>
</div>


