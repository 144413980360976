import { OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
var SidemenuComponent = /** @class */ (function () {
    function SidemenuComponent(navigatorService) {
        this.navigatorService = navigatorService;
        this.iconOnly = false;
        if (this.sidenav != null)
            this.navigatorService.Sidenav = this.sidenav;
    }
    SidemenuComponent.prototype.ngOnInit = function () {
    };
    return SidemenuComponent;
}());
export { SidemenuComponent };
