import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { NotificationModel } from '../models/NotificationModel';
import { DataService } from '../services/data.service';
import { Paging } from '../base/BaseModel';

@Component({
  selector: 'cdk-toolbar-notification',
  templateUrl: './toolbar-notification.component.html',
  styleUrls: ['./toolbar-notification.component.scss']
})
export class ToolbarNotificationComponent implements OnInit {

  public Notification: NotificationModel;
  cssPrefix = 'toolbar-notification';
  isOpen: boolean = false;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  constructor(private elementRef: ElementRef, private dataService: DataService) {
    this.Load();
  }

  public async Load() {
    var paging = new Paging(10);
    this.Notification = await this.dataService.LoadEffectiveNotifications(paging);
  }

  ngOnInit() {
  }


}
