var BaseModel = /** @class */ (function () {
    function BaseModel() {
        this.Paging = new Paging();
        this.Sortings = new Array();
        this.Count = 0;
        this.Search = null;
        this.Performed = false;
    }
    return BaseModel;
}());
export { BaseModel };
var BaseEntity = /** @class */ (function () {
    function BaseEntity() {
    }
    Object.defineProperty(BaseEntity.prototype, "Id", {
        get: function () {
            return this.id;
        },
        set: function (value) {
            this.id = value;
        },
        enumerable: true,
        configurable: true
    });
    return BaseEntity;
}());
export { BaseEntity };
export var Direction;
(function (Direction) {
    Direction["None"] = "None";
    Direction["Ascending"] = "Ascending";
    Direction["Descending"] = "Descending";
})(Direction || (Direction = {}));
var Sorting = /** @class */ (function () {
    function Sorting() {
    }
    return Sorting;
}());
export { Sorting };
var Paging = /** @class */ (function () {
    function Paging(take) {
        this.Page = 0;
        this.Take = take || 5;
    }
    Object.defineProperty(Paging.prototype, "Skip", {
        get: function () {
            var skip = this.Page * this.Take;
            return skip;
        },
        enumerable: true,
        configurable: true
    });
    return Paging;
}());
export { Paging };
