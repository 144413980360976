import { Routes } from '@angular/router';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
export var ModuleRoutes = [
    { path: 'home', component: DashboardHomeComponent },
];
var DashboardModule = /** @class */ (function () {
    function DashboardModule() {
    }
    return DashboardModule;
}());
export { DashboardModule };
