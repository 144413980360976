import { Component, Input } from '@angular/core';
import { IBaseView, ViewMode } from '../base/BaseView';
import { NavigatorService } from '../services/navigator.service';

@Component({
    selector: 'app-view-container',
    templateUrl: './view-container.component.html',
    styleUrls: ['./view-container.component.scss']
})

export class ViewContainerComponent {

    @Input()
    public Editing: boolean = true;

    @Input()
    public Icon: string = "";

    @Input()
    public SwitchMode = false;

    @Input()
    public Title: string;

    @Input()
    public GoBackVisible: boolean = true;

    @Input()
    public ViewComponent: IBaseView;

    private view: ViewMode;
    @Input()
    public get View(): ViewMode {
        return this.view;
    }
    public set View(view: ViewMode) {
        this.view = view;
        this.SetViewMode(view);
    }

    public ViewMode = ViewMode;

    constructor(public navigatorService: NavigatorService) {

    }

    public Add() {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.Add();
    }

    public Refresh() {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.Refresh();
    }

    public SearchChange(search: string) {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.Searching(search);
    }

    public GoBack() {
        this.navigatorService.GoBack();
    }

    public SetViewMode(view: ViewMode) {
        var viewComponent = this.ViewComponent;
        if (viewComponent != null)
            viewComponent.View = view;
    }

}


