<div class="toolbar-notification-container">
	<button mat-icon-button (click)="isOpen = !isOpen;" [ngClass]="[cssPrefix+'-btn']" matTooltip="Click per visualizzare tutte le notifiche...">
    	<mat-icon class="toolbar-icon" >notifications_none</mat-icon>
      <span class="badge" *ngIf="this.Notification && this.Notification.Count>=1">{{ this.Notification?.Count }}</span>
  </button>

	<div class="dropdown mat-elevation-z4" [class.open]="isOpen">
	  <div class="card">
      	<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        	<div class="title">
         		  <div class="name">Notifiche</div>
                <div class="extra">
                  Hai {{ this.Notification?.Count }} nuove notifiche
                </div>
        	</div>
        	<!--<button type="button" mat-icon-button>
          		<mat-icon class="icon">settings</mat-icon>
        	</button>-->
      	</div>
      	<div *ngIf="this.Notification?.Count>=1; then thenBlock else elseBlock;"></div>
      	<div class="footer" fxLayout="row" fxLayoutAlign="center center" style="background:whitesmoke">
        	  <!--<div class="action">Visualizza tutte le notifiche</div>-->
    	  </div>
    </div>
	</div>
</div>

<ng-template #thenBlock>
  <perfect-scrollbar class="content">
    <div *ngFor="let dto of this.Notification?.Dtos; last as isLast">
      <div class="notification" fxLayout="row" fxLayoutAlign="start center" mat-ripple>
        <mat-icon class="icon">notifications</mat-icon>
        <div class="title" fxLayout="column">
          <div class="name">{{ dto.Titolo }}</div>
          <div class="time">{{ dto.Elapsed }}</div>
        </div>
        <span fxFlex></span>
        <button type="button" mat-icon-button>
          <mat-icon class="close">close</mat-icon>
        </button>
      </div>
      <div class="divider" *ngIf="!isLast"></div>
    </div>
  </perfect-scrollbar>
</ng-template>

<ng-template #elseBlock>
  <div class="no" fxLayout="row" fxLayoutAlign="center center"></div>
</ng-template>

