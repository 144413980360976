import { Component } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})

export class ExternalComponent {

  constructor(public navigatorService: NavigatorService) {

  }
}
