import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent {

  public Title: string;
  public Message: string;
  public ConfirmCallback: () => Promise<void>;

  constructor(public dialog: MatDialogRef<PopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DataDialog) {
    if (data != null) {
      this.Title = data.Title;
      this.Message = data.Message;
      this.ConfirmCallback = data.ConfirmCallback;

    }
  }

  public Cancel() {
    this.dialog.close();
  }

  public Confirm() {
    if (this.ConfirmCallback != null)
      this.ConfirmCallback();

    this.dialog.close();
  }
  
}

export class DataDialog {
  public Title: string;
  public Message: string;
  public ConfirmCallback: ()=> Promise<void>
}
