<mat-tab-group [(selectedIndex)]="this.navigatorService.SidebarTabIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">assignment</mat-icon>
      <strong>Informazioni</strong>
    </ng-template>

    <div class="sidebar">
      <div fxLayout="column" fxLayoutAlign="center center" style="padding: 30px 0; height:130px; background: url('./assets/images/bg/heading-sidebar.jpg');">
        <div style="width: 100%; height: 130px; position: absolute;background: rgba(0, 0, 0, 0.40);z-index: 1"></div>
        <div class="mat-display-2" style="margin: 0; color: white;z-index: 2">{{this.Today | date:'shortTime'}}</div>
        <div class="mat-subheading-2" style="margin: 0;color: white;z-index: 2">
          {{this.Today | date:'EEEE'}}, {{this.Today | date:'MMMM'}} {{this.Today | date:'dd'}}
        </div>
      </div>

      <mat-divider></mat-divider>

      <h3 class="mat-subheading-2" style="margin: 10px 14px 0; font-weight: bold;">Attività</h3>

      <mat-divider></mat-divider>

    </div>
  </mat-tab>

  <mat-tab >
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">settings_phone</mat-icon>
      <strong>Contatti</strong>
    </ng-template>
    <div class="sidebar" >

      <div fxLayout="column" fxLayoutAlign="center center" style="padding: 30px 0; height:130px; background: url('./assets/images/bg/heading-sidebar.jpg');">
        <div style="width: 100%; height: 130px; position: absolute;background: rgba(0, 0, 0, 0.40);z-index: 1"></div>
        <div class="mat-display-2" style="margin: 0; color: white;z-index: 2">Contact</div>
      </div>

      <mat-divider></mat-divider>

      <div style="padding:10px;">
        <h3 class="mat-subheading-2" style="margin: 10px 14px 0; font-weight: bold;">Telefono</h3>
        <div fxLayout="row" fxLayoutAlign="start center" mat-ripple style="padding-left:20px;">
          <div fxLayout="column">
            <table style="width:100%">
              <tr>
                <td valign="top">
                  <img src="../../../assets/images/tools/phone.png" class="image-contact" />
                </td>
                <td align="left" valign="middle" style="padding-left:5px; padding-top:2px;">
                  <div class="mat-subheading-2 title" style="margin: 0;">{{this.navigatorService.Contact?.Phone}}</div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <h3 class="mat-subheading-2" style="position:relative; margin: 10px 14px 0; font-weight: bold;">Email</h3>
        <div fxLayout="row" fxLayoutAlign="start center" mat-ripple style="padding-left:20px;">
          <div fxLayout="column">
            <table style="width:100%">
              <tr>
                <td valign="top">
                  <img src="../../../assets/images/tools/mail.png" class="image-contact" />
                </td>
                <td align="left" valign="middle" style="padding-left:5px; padding-top:2px;">
                  <div class="mat-subheading-2 title" style="margin: 0;">{{this.navigatorService.Contact?.Email}}</div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

  </mat-tab>
</mat-tab-group>

