import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss']
})

export class EditCardComponent {
  @Input()
  public Title: string;

  @Input()
  public Icon: string = "info_outline";

  constructor() {

  }
}
