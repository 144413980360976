var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseModel, BaseEntity } from "../base/BaseModel";
var NotificationModel = /** @class */ (function (_super) {
    __extends(NotificationModel, _super);
    function NotificationModel() {
        return _super.call(this) || this;
    }
    return NotificationModel;
}(BaseModel));
export { NotificationModel };
var NotificationEntity = /** @class */ (function (_super) {
    __extends(NotificationEntity, _super);
    function NotificationEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NotificationEntity;
}(BaseEntity));
export { NotificationEntity };
var NotificationDto = /** @class */ (function (_super) {
    __extends(NotificationDto, _super);
    function NotificationDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NotificationDto;
}(NotificationEntity));
export { NotificationDto };
var NotificationFilter = /** @class */ (function (_super) {
    __extends(NotificationFilter, _super);
    function NotificationFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NotificationFilter;
}(NotificationDto));
export { NotificationFilter };
