import { Component, Input, OnInit } from '@angular/core';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { DataService } from '../services/data.service';
import { NavigatorService } from '../services/navigator.service';


@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']

})

export class ContainerComponent implements OnInit {
  @Input() isVisible: boolean = true;
  visibility = 'shown';
  sideNavOpened: boolean = true;
  matDrawerOpened: boolean = false;
  matDrawerShow: boolean = true;
  sideNavMode: string = 'side';

  ngOnChanges() {
    this.visibility = this.isVisible ? 'shown' : 'hidden';
  }

  constructor(private media: ObservableMedia, private dataService: DataService, public navigatorService: NavigatorService) {
    //impostazioni di autenticazione
    this.navigatorService.Start();
    this.dataService.GetAuthentication().then(model => {
      if (model != null) {
        var dto = model.Dto;
        if (dto != null) {
          var autenticated = dto.Authenticated;
          if (autenticated) {
            this.navigatorService.SetUserData(model);
          }
        }
      } else {
        this.navigatorService.GoTo("/login");
      }
    });
  }

  ngOnInit() {
    this.media.subscribe((mediaChange: MediaChange) => {
      this.ToggleView();
    });
  }

  ToggleView() {
    if (this.media.isActive('gt-md')) { //desktop
      this.sideNavMode = 'side';
      this.sideNavOpened = true;
      this.matDrawerOpened = false;
      this.matDrawerShow = true;
      this.navigatorService.DesktopMode = true;
      this.navigatorService.TabletMode = false;
      this.navigatorService.SmartphoneMode = false;
    } else if (this.media.isActive('gt-xs')) { //tablet
      this.sideNavMode = 'side';
      this.sideNavOpened = false;
      this.matDrawerOpened = true;
      this.matDrawerShow = true;
      this.navigatorService.DesktopMode = false;
      this.navigatorService.TabletMode = true;
      this.navigatorService.SmartphoneMode = false;
    } else if (this.media.isActive('lt-sm')) { //smartphone
      this.sideNavMode = 'over';
      this.sideNavOpened = false;
      this.matDrawerOpened = false;
      this.matDrawerShow = false;
      this.navigatorService.DesktopMode = false;
      this.navigatorService.TabletMode = false;
      this.navigatorService.SmartphoneMode = true;
    }
  }


}
