var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit } from '@angular/core';
import { MatCalendar } from '@angular/material';
import { StatisticBarModel } from '../../core/models/StatisticBarModel';
import { CalendarService } from '../../core/services/calendar.service';
import { NavigatorService } from '../../core/services/navigator.service';
import { Controller, DataService } from '../../data.service';
var DashboardHomeComponent = /** @class */ (function () {
    function DashboardHomeComponent(dataService, navigatorService, calendarService) {
        var _this = this;
        this.dataService = dataService;
        this.navigatorService = navigatorService;
        this.calendarService = calendarService;
        this.ColorScheme = {
            domain: ['#7ED321', '#F5A623', '#ff2d2d', '#AAAAAA']
        };
        this.navigatorService.IsAuthenticated().then(function (authenticated) {
            if (authenticated) {
                _this.LoadStatistic();
                _this.LoadWhatsNews();
            }
            else
                _this.navigatorService.GoTo("/login");
        });
    }
    DashboardHomeComponent.prototype.ngAfterViewInit = function () {
    };
    DashboardHomeComponent.prototype.LoadStatistic = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.dataService.Get(Controller.Statistic)];
                    case 1:
                        _a.Statistic = _b.sent();
                        this.PraticheStatistics = this.GetPraticheStatistics(this.Statistic);
                        this.ComunicazioniStatistics = this.GetComunicazioniStatistics(this.Statistic);
                        this.PagamentiStatistics = this.GetPagamentiStatistics(this.Statistic);
                        this.RichiesteStatistics = this.GetRichiesteStatistics(this.Statistic);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardHomeComponent.prototype.LoadWhatsNews = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.dataService.Load(Controller.WhatsNew, { Skip: 0, Take: 5 }, [{ Name: "Start", Direction: "Ascending" }], null, { Start: new Date(), Alert: true })];
                    case 1:
                        _a.WhatsNew = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.dataService.Get(Controller.WhatsNew, null, { Date: new Date() })];
                    case 2:
                        _b.Scheduler = _c.sent();
                        this.Calendar.updateTodaysDate();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardHomeComponent.prototype.GetPraticheStatistics = function (statistic) {
        var statisticsBar = new Array();
        if (statistic != null) {
            var dtos = statistic.StatisticheAnno;
            if (dtos != null) {
                for (var _i = 0, dtos_1 = dtos; _i < dtos_1.length; _i++) {
                    var dto = dtos_1[_i];
                    var statisticBar = new StatisticBarModel();
                    statisticBar.name = dto.Anno.toString();
                    var series = new Array();
                    series.push({ name: "Chiuse", value: dto.PraticheChiuse });
                    series.push({ name: "Aperte", value: dto.PraticheAperte });
                    series.push({ name: "Verificare", value: dto.PraticheErrore });
                    statisticBar.series = series;
                    statisticsBar.push(statisticBar);
                }
            }
        }
        return statisticsBar;
    };
    DashboardHomeComponent.prototype.GetComunicazioniStatistics = function (statistic) {
        var bars = new Array();
        if (statistic != null) {
            bars.push({ name: "Email", value: statistic.CountComunicazioniEmail });
            bars.push({ name: "SMS", value: statistic.CountComunicazioniSMS });
            bars.push({ name: "Errore", value: statistic.CountComunicazioniErrore });
        }
        return bars;
    };
    DashboardHomeComponent.prototype.GetPagamentiStatistics = function (statistic) {
        var bars = new Array();
        if (statistic != null) {
            bars.push({ name: "Autorizzati", value: statistic.CountPagamentiAutorizzati });
            bars.push({ name: "Rifiutati", value: statistic.CountPagamentiRifiutati });
            bars.push({ name: "Verificare", value: statistic.CountPagamentiVerificare });
        }
        return bars;
    };
    DashboardHomeComponent.prototype.GetRichiesteStatistics = function (statistic) {
        var bars = new Array();
        if (statistic != null) {
            bars.push({ name: "Contatti LP", value: statistic.CountRichiesteLandingPage });
            bars.push({ name: "Richieste OTP", value: statistic.CountRichiesteOTP });
        }
        return bars;
    };
    DashboardHomeComponent.prototype.GetHighlightDates = function () {
        var _this = this;
        return function (date) {
            var className = "";
            if (_this.Scheduler != null) {
                var dtos = _this.Scheduler.Dtos;
                if (dtos != null) {
                    var dateUTC = _this.calendarService.GetDateUTC(date);
                    var datesUTC = dtos.map(function (q) { return _this.calendarService.GetDateUTC(q.Start); });
                    var highlightDate = datesUTC.find(function (q) { return q == dateUTC; }) != null;
                    if (highlightDate)
                        className = "mat-calendar-date-highlight";
                }
            }
            return className;
        };
    };
    DashboardHomeComponent.prototype.MonthChange = function (calendar) {
        return __awaiter(this, void 0, void 0, function () {
            var view, date, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        view = calendar.monthView;
                        if (!(view != null)) return [3 /*break*/, 2];
                        date = view.activeDate;
                        if (!(date != null)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.dataService.Get(Controller.WhatsNew, null, { Date: new Date(date) })];
                    case 1:
                        _a.Scheduler = _b.sent();
                        this.Calendar.updateTodaysDate();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DashboardHomeComponent.prototype.ShowPraticheVerificare = function () {
        this.navigatorService.GoTo("/container/pratica/view", null, { Verificare: true });
    };
    DashboardHomeComponent.prototype.ShowPagamentiVerificare = function () {
        this.navigatorService.GoTo("/container/pagamento/view", null, { Verificare: true });
    };
    DashboardHomeComponent.prototype.abbreviateNumber = function (number) {
        var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        var tier = Math.log10(Math.abs(number)) / 3 | 0;
        // if zero, we don't need a suffix
        if (tier == 0)
            return number;
        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);
        // scale the number
        var scaled = number / scale;
        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    };
    return DashboardHomeComponent;
}());
export { DashboardHomeComponent };
