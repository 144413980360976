import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ApplicationRoutes } from './app.configuration';
import { CoreModule } from './core/core.module';
import { DataService } from './data.service';
import { NavigatorService } from './core/services/navigator.service';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DashboardModule,
        CoreModule,
        RouterModule.forRoot(ApplicationRoutes),
    ],
    providers: [
        DataService,
        NavigatorService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
