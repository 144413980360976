<mat-nav-list [style.maxHeight]="(menu.Open)?'1200px':'64px'"  [ngClass]="{'sidemenu-secondary': secondaryMenu, 'sidemenu-primary': !secondaryMenu}">
  <mat-list-item *ngIf="menu.Link==null" class="sidemenu-listitem sidemenu-item sidemenu-color" style="color:black;" (click)="ShowMenu(menu)" matTooltip="{{ menu.Name }}" matTooltipPosition="right" [matTooltipDisabled]="!iconOnly">
    <table *ngIf="iconOnly" class="sidemenu-table">
      <tr>
        <td align="center" style="width:70px;">
          <mat-icon matListIcon iconsmall  class="sidemenu-item-shift">{{menu.Icon}}</mat-icon>
        </td>
        <td *ngIf="HasChildMenu()" align="center" style="width:25px;">
          <mat-icon class="sidenav-dropdown-indicator rotate" [ngClass]="{'indicateOpen':menu.Open}"> expand_more</mat-icon>
        </td>
      </tr>
      <tr>
        <td align="center" style="width:70px">
          <small matLine  class="sidemenu-item-shift">{{ menu.ShortName }}</small>
        </td>
        <td *ngIf="HasChildMenu()" align="center" style="width:25px;">
      </tr>
    </table>

    <mat-icon matListIcon iconsmall *ngIf="!iconOnly">{{menu.Icon}}</mat-icon>
    <small matLine *ngIf="!iconOnly">{{ menu.Name }}</small>
    <mat-chip-list *ngIf="menu?.chip && !iconOnly">
      <mat-chip>{{menu?.Chip?.value}}</mat-chip>
    </mat-chip-list>
    <mat-icon *ngIf="HasChildMenu() && !iconOnly" class="sidenav-dropdown-indicator rotate" [ngClass]="{'indicateOpen':menu.Open}"> expand_more</mat-icon>

  </mat-list-item>

  <mat-list-item *ngIf="menu.Link!=null" class="sidemenu-item sidemenu-color" (click)="ShowMenu(menu)"  [ngClass]="menu.Active?'sidemenu-active':''" matTooltip="{{ menu.Name }}" matTooltipPosition="right" [matTooltipDisabled]="!iconOnly">
    <table *ngIf="iconOnly" class="sidemenu-table">
      <tr>
        <td align="center" style="width:70px;">
          <mat-icon matListIcon iconsmall class="sidemenu-item-shift">{{menu.Icon}} </mat-icon>
        </td>
      </tr>
      <tr>
        <td align="center" style="width:70px">
          <small matLine class="sidemenu-item-shift">{{ menu.ShortName }}</small>
        </td>
      </tr>
    </table>

    <mat-icon matListIcon iconsmall *ngIf="!iconOnly">{{menu.Icon}}</mat-icon>
    <h3 matLine *ngIf="!iconOnly">{{ menu.Name }}</h3>
  </mat-list-item>

  <cdk-sidemenu-item *ngFor="let subMenu of menu?.SubMenus" [menu]="subMenu" [iconOnly]="iconOnly" [secondaryMenu]="true"> </cdk-sidemenu-item>

</mat-nav-list>
