import { NavigatorService } from '../services/navigator.service';
import { BaseDataService } from './BaseDataService';
import { IBaseModel, Sorting, Paging, Direction, BaseEntity } from './BaseModel';
import { TableColumnComponent } from '../table-column/table-column.component';
import { ViewChildren, EventEmitter, AfterViewInit, QueryList, OnInit } from '@angular/core';

export class BaseView<TModel extends IBaseModel> implements IBaseView, AfterViewInit, OnInit {

  @ViewChildren(TableColumnComponent)
  public Columns: QueryList<TableColumnComponent>;
  public Paging: Paging;
  public Sortings: Array<Sorting>;
  public Controller: string;
  public Action: string;
  public Search: string;
  public Filter: any;
  public PagingMode: PagingMode;

  private model: TModel;
  public get Model(): TModel {
    return this.model;
  }
  public set Model(model: TModel) {
    this.model = model;
    this.ModelChange.emit(model);
  }

  public ModelChange: EventEmitter<TModel>;

  public View: ViewMode;
  public TakeMax: number = 1000;

  constructor(public dataService: BaseDataService, public navigatorService: NavigatorService, public controller: string, public action?: string, public take?: number) {
    if (take == null) { //todo: view without paging - da aggiustare
      take = this.TakeMax;
      this.navigatorService.Paging = new Paging(take);
    }

    this.Controller = controller;
    this.Action = action;
    this.Paging = this.navigatorService.Paging || new Paging(take);
    this.Sortings = this.navigatorService.Sortings || new Array<Sorting>();
    this.Filter = this.navigatorService.Filter;
    this.PagingMode = (take != null && take < this.TakeMax ? PagingMode.SkipTake : PagingMode.Filtering);
    this.ModelChange = new EventEmitter<TModel>();

    this.PreloadData(); //preload data (only for SkipTake paging mode)

    this.navigatorService.IsAuthenticated().then((authenticated) => {
      if (!authenticated)
        this.navigatorService.GoTo("/login");
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      var columns = this.Columns;
      var sortings = this.Sortings;
      if (sortings != null && columns != null) {
        for (var sorting of sortings) {
          var name = sorting.Name;
          var direction = sorting.Direction;
          var column = columns.find(q => q.Name == name);
          if (column != null)
            column.Direction = direction;
        }
      }
    });
  }

  ngOnInit() {

  }

  public async PreloadData() {
    var pagingMode = this.PagingMode;
    if (pagingMode == PagingMode.SkipTake) {
      var paging = this.Paging;
      var sortings = this.Sortings;
      var filter = this.Filter;

      if (this.Action == "StatoPagamenti") {

        var search = this.Search;

        var request = <TModel>{};
        request.Paging = paging;
        request.Sortings = sortings;
        request.Search = search;
        request.Filter = filter;

        this.Model = await this.dataService.AsyncPost<TModel>(this.Controller, this.Action, request);
      } else {
        this.Model = await this.Load<TModel>(paging, sortings, null, filter);
      }
    }
  }

  public async Refresh() {
    var paging = this.Paging;
    var sortings = this.Sortings;
    var filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;

    this.Model = await this.Load<TModel>(paging, sortings, null, filter);
  }

  public Add() {
    var action = this.Action;
    if (action != null) {
      this.navigatorService.GoTo(action, { Id: 0 }, this.Filter);
    }
  }

  public Edit(dto: any) {
    var action = this.Action;
    if (action != null) {
      this.navigatorService.GoTo(action, dto, this.Filter);
    }
  }

  public Show(action: string, filter?: any) {
    if (action != null) {
      this.navigatorService.GoTo(action, null, filter);
    }
  }

  public async Load<TModel extends IBaseModel>(paging: Paging, sortings: Array<Sorting>, search?: string, filter?: any): Promise<TModel> {
    var controller = this.Controller;
    if (controller != null) {
      this.navigatorService.Start();
      if (this.Action == "StatoPagamenti") {

        var search = this.Search;

        var request = <TModel>{};
        request.Paging = paging;
        request.Sortings = sortings;
        request.Search = search;
        request.Filter = filter;

        var model = await this.dataService.AsyncPost<TModel>(this.Controller, this.Action, request);
      } else {
        var model = await this.dataService.Load<TModel>(controller, paging, sortings, search, filter);
      }
      this.navigatorService.Stop();
      return model;
    }
    return null;
  }

  public async Get<TModel extends IBaseModel>(search?: string, filter?: any): Promise<TModel> {
    var controller = this.Controller;
    if (controller != null) {
      this.navigatorService.Start();
      var model = await this.dataService.Get<TModel>(controller, search, filter);
      this.navigatorService.Stop();
      return model;
    }
    return null;
  }

  private async LoadData(paging: Paging, sortings: Array<Sorting>, search?: string, filter?: any) {
    var pagingMode = this.PagingMode;
    if (pagingMode == PagingMode.SkipTake)
      this.Model = await this.Load<TModel>(paging, sortings, search, filter);
    else
      this.Model = await this.Get<TModel>(search, filter);
  }

  public async Searching(search: string) {
    var paging = this.Paging;
    var sortings = this.Sortings;
    var filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;

    this.Search = search;
    this.LoadData(paging, sortings, search, filter);
  }

  public async Filtering(filter: any) {
    var paging = this.Paging;
    var sortings = this.Sortings;
    var search = this.Filter != null ? this.Filter : this.navigatorService.Filter;

    this.Filter = filter;
    this.LoadData(paging, sortings, search, filter);
  }

  public async PagingChange(paging: Paging) {
    if (paging != null) {
      var sortings = this.Sortings;
      var search = this.Search;
      var filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;

      this.Paging = paging;
      this.LoadData(paging, sortings, search, filter);
    }
  }

  public async SortingChange(sorting: Sorting) {
    if (sorting != null) {
      //todo: limitazione ad 1 solo sorting
      this.Sortings = new Array<Sorting>();

      var direction = sorting.Direction;
      if (direction == Direction.None)
        this.RemoveSorting(sorting);
      else
        this.AddSorting(sorting);

      var paging = this.Paging;
      var sortings = this.Sortings;
      var search = this.Search;
      var filter = this.Filter != null ? this.Filter : this.navigatorService.Filter;

      this.LoadData(paging, sortings, search, filter);
      this.navigatorService.Sortings = sortings;
    }
  }

  public async FilteringChange(filter: any) {
    var paging = this.Paging;
    var sortings = this.Sortings;
    var search = this.Search;

    this.LoadData(paging, sortings, search, filter);
  }

  private AddSorting(sorting: Sorting) {
    if (sorting != null) {
      var sortings = this.Sortings;
      if (sortings != null) {
        var sortingExist = sortings.find(q => q.Name == sorting.Name);
        if (sortingExist != null)
          sortingExist.Direction = sorting.Direction;
        else
          this.Sortings.push(sorting);
      }
    }
  }

  private RemoveSorting(sorting: Sorting) {
    if (sorting != null) {
      var sortings = this.Sortings;
      if (sortings != null)
        this.Sortings = sortings.filter(q => q.Name != sorting.Name);
    }
  }

}

export interface IBaseView {
  Add();
  Refresh();
  Edit(dto: any);
  Load<TModel extends IBaseModel>(paging: Paging, sortings: Array<Sorting>, search?: string, filter?: any): Promise<TModel>;
  Get<TModel extends IBaseModel>(search?: string, filter?: any): Promise<TModel>;
  PagingChange(paging: Paging);
  SortingChange(sorting: Sorting);
  FilteringChange(filter: any);
  ModelChange: EventEmitter<any>;
  Searching(search: string);
  Filtering(filter: any);
  View: ViewMode;
  Columns: QueryList<TableColumnComponent>;
}

export enum PagingMode {
  SkipTake,
  Filtering
}

export enum ViewMode {
  Table = "Table",
  Card = "Card",
}
