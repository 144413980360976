var ValidationService = /** @class */ (function () {
    function ValidationService() {
    }
    ValidationService.prototype.IsValidText = function (value) {
        var validated = (value != null && value.toString().length >= 1);
        return validated;
    };
    ValidationService.prototype.IsValidDate = function (value) {
        var validated = false;
        if (value != null) {
            var date = new Date(value);
            var timestamp = Date.parse(value);
            validated = (date != null && date instanceof Date && !isNaN(timestamp));
        }
        return validated;
    };
    ValidationService.prototype.IsValidEmail = function (value) {
        var expression = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var validated = expression.test(value);
        return validated;
    };
    ValidationService.prototype.IsValidNumeric = function (value) {
        var expression = new RegExp('^[0-9]+$');
        var validated = expression.test(value);
        return validated;
    };
    return ValidationService;
}());
export { ValidationService };
