<mat-toolbar class="mat-elevation-z4">
  <button mat-icon-button (click)="ToggleSidenav();" *ngIf="matDrawerShow" matTooltip="Espande o chiude il menù laterale...">
    <i class="material-icons app-toolbar-menu">menu </i>
  </button>
  <button mat-icon-button (click)="ToggleSidenav();" *ngIf="!matDrawerShow">
    <i class="material-icons app-toolbar-menu">menu </i>
  </button>
  <span class="spacer"></span>
  <!--<button  mat-icon-button (click)="searchOpen = !searchOpen" fxHide="true" [fxHide.gt-xs]="false" matTooltip="Digita qualsiasi parola per effettuare una ricerca..." [matTooltipDisabled]="searchOpen">
    <i class="material-icons toolbar-icon">search</i>
  </button>
  <cdk-search-bar [open]="searchOpen" ></cdk-search-bar>-->
  <!--<cdk-fullscreen matTooltip="Click to view in full-screen mode (click again for window-mode)..."></cdk-fullscreen>-->
  <cdk-toolbar-notification></cdk-toolbar-notification>
  <cdk-user-menu></cdk-user-menu>
  <button class="toolbar-button-mini" mat-button matTooltip="Visualizza maggiori informazioni..." (click)="SetSidebarTab(0)">
    <i class="material-icons app-toolbar-menu toolbar-icon">info_outlined </i><span class="toolbar-text"></span>
  </button>

</mat-toolbar>
