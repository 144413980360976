import { ElementRef, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { NavigatorService } from '../services/navigator.service';
var UserMenuComponent = /** @class */ (function () {
    function UserMenuComponent(elementRef, navigatorService, dataService) {
        this.elementRef = elementRef;
        this.navigatorService = navigatorService;
        this.dataService = dataService;
        this.isOpen = false;
    }
    UserMenuComponent.prototype.onClick = function (event, targetElement) {
        if (!targetElement) {
            return;
        }
        var clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }
    };
    UserMenuComponent.prototype.ngOnInit = function () {
    };
    UserMenuComponent.prototype.Logout = function () {
        var _this = this;
        //avvio procedura di logout utente
        this.dataService.Logout().then(function (model) {
            _this.navigatorService.GoTo("/login");
        });
    };
    return UserMenuComponent;
}());
export { UserMenuComponent };
