
<div class="chatbot-start">
  <img src="./assets/images/tools/chatbot_animated.gif" style="width:64px" (click)="ShowHideChatbot()" />
</div>

<div *ngIf="this.ShowingChatbot" class="chatbot-container">
  <div class="chatbot-header">
    <table style="width:100%">
      <tr>
        <td style="padding-left:10px; ">
          <span style="font-size:12px;color:white;">Chat-bot</span>
        </td>
        <td style="width:20px; padding-right:5px;">
          <img src="./assets/images/tools/close_white.png" style="position:relative;top:-2px; width:20px; cursor:pointer" (click)="HideChatbot()" />
        </td>
      </tr>
    </table>
  </div>

  <div class="chatbot-body">
    <perfect-scrollbar #scrollbar class="chatbot-message-container" >
      <app-pagamento-chatbot-message *ngFor="let chatbot of this.Chatbots" [Direction]="chatbot.Direction" [Message]="chatbot.Message" [Scrollbar]="scrollbar"></app-pagamento-chatbot-message>
    </perfect-scrollbar>
  </div>

  <div class="chatbot-footer">
    <table style="width:100%">
      <tr>
        <td style="padding-left:10px;">
          <mat-form-field style="width:95%; font-size:12px;">
            <mat-label></mat-label>
            <input #editMessage matInput placeholder="Digita qui una domanda..." autofocus>
          </mat-form-field>
        </td>
        <td style="width:24px;">
          <img src="./assets/images/tools/chatbot_arrow_dx.png" style="width:20px;cursor:pointer" (click)="SendMessage(editMessage.value)" />
        </td>
      </tr>
    </table>

  </div>
</div>
