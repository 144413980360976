import { BaseModel, BaseEntity } from "../base/BaseModel";

export class NotificationModel extends BaseModel<NotificationEntity, NotificationDto, NotificationFilter> {


    constructor() {
        super();
        
    }
}


export class NotificationEntity extends BaseEntity {
    public Title: string;
    public Elapsed: string;
    public Status: string;

}

export class NotificationDto extends NotificationEntity {

}

export class NotificationFilter extends NotificationDto {
    
}
