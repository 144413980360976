import { Component, ViewChild } from '@angular/core';
import { ChatbotModel } from '../../models/ChatbotModel';
import { DataService, Controller, Action } from '../../data.service';
import { FaqModel } from '../../models/FaqModel';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-pagamento-chatbot',
  templateUrl: './pagamento-chatbot.component.html',
  styleUrls: ['./pagamento-chatbot.component.scss']
})

export class PagamentoChatbotComponent {

  public Chatbots: Array<ChatbotModel>;
  public ShowingChatbot: boolean = false;

  constructor(public dataService: DataService) {
    this.InitView();
    this.ShowBenvenuto();
  }

  private InitView() {
    this.Chatbots = new Array<ChatbotModel>();
  }


  private AddChatbotMessage(chatbot: ChatbotModel) {
    if (chatbot != null) {
      var timeout = chatbot.Direction == "left" ? 2000 : 125;
      setTimeout(() => {
        var chatbots = this.Chatbots;
        chatbots.push(chatbot);

        this.Chatbots = chatbots;
      }, timeout);
    }
  }


  public HideChatbot() {
    this.ShowingChatbot = false;
  }

  public ShowHideChatbot() {
    this.ShowingChatbot = !this.ShowingChatbot;
  }

  public async SendMessage(message: string) {
    this.ShowMessage("right", message);

    //risposta chatbot con IA di ricerca nelle Faq
    var request = new FaqModel();
    request.Search = message;
    var model = await this.dataService.AsyncPost<FaqModel>(Controller.Faq, Action.Search, request);
    if (model != null && model.Performed) {
      var dtos = model.Dtos;
      if (dtos != null && dtos.length >= 1) {
        var dto = dtos[0]; //considero solo la prima risposta
        this.ShowMessage("left", dto.Risposta);
      }
      else
        this.ShowIncomprensione();
    }
    else {
      //risposta con messaggio di incomprensione!!!
      this.ShowIncomprensione();
    }
  }

  private ShowBenvenuto() {
    var chatbot = new ChatbotModel("left", "Ciao io sono il tuo assistente Chatbot!. Digita pure una domanda attinente al pagamento, cercherò di aiutarti");
    this.AddChatbotMessage(chatbot);
  }

  private ShowMessage(direction: string, message: string) {
    var chatbot = new ChatbotModel(direction, message);
    this.AddChatbotMessage(chatbot);
  }

  private ShowIncomprensione() {
    var chatbot = new ChatbotModel("left", "Mi dispiace ma mi occorrono più informazioni, puoi riformulare la domanda?");
    this.AddChatbotMessage(chatbot);
  }

}
