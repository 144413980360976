import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule, MatSelectModule, MatRadioModule, MatGridListModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatCardModule, MatListModule, MatButtonModule, MatTooltipModule, MatIconModule, MatToolbarModule } from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CoreModule } from '../core/core.module';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';


export const ModuleRoutes: Routes = [
  { path: 'home', component: DashboardHomeComponent },
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSelectModule,
    MatRadioModule,
    MatGridListModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    NgxChartsModule,    
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    CoreModule,
    RouterModule.forChild(ModuleRoutes),
  ],
  declarations: [DashboardHomeComponent],
  exports: [ ]
})
export class DashboardModule { }
