import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {


    constructor() {

    }

    public IsValidText(value: string): boolean {
        var validated = (value != null && value.toString().length >= 1);
        return validated;
    }

    public IsValidDate(value: any): boolean {
        var validated = false;
        if (value != null) {
            var date = new Date(value);
            var timestamp = Date.parse(value);
            validated = (date != null && date instanceof Date && !isNaN(timestamp));
        }
        return validated;
    }

    public IsValidEmail(value: any): boolean {
        var expression = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var validated = expression.test(value);
        return validated;
    }

    public IsValidNumeric(value: any): boolean {
        var expression = new RegExp('^[0-9]+$');
        var validated = expression.test(value);
        return validated;
    }

}



