import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IBaseModel, Sorting, Paging } from './BaseModel';

export const API_URL = "./api/"; //url di chiamata al servizio API Controller (per default l'url è impostato in localhost)
export const HttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //'tokenPFX': 'jwt-0a2c227e-c1ce-4dbd-9d7a-0c0f1dcdf034' //TEST
    'tokenPFX': 'jwt-0b3ffe37-a34b-gh4r-9u4r-0def14r3ecd4' //PROD
  })
};

export enum Action {
  None = "None",
  Create = "Create",
  Read = "Read",
  Update = "Update",
  Delete = "Delete",
  Count = "Count",
  Load = "Load",
  Get = "Get",
  Find = "Find",
  Login = "Login",
  Logout = "Logout",
  Authentication = "Authentication",
  Save = "Save",
}

export class BaseDataService {
  constructor(public http: HttpClient) {

  }

  public async Call<TModel>(controller: string, action: string, request: any) { //chiamata di tipo raw (formdata, post direct atipizzato)
    var response = await this.http.post<TModel>(API_URL + controller + "/" + action, request).toPromise();
    return response;
  }

  public async AsyncGet(controller: string, action: string)
  {
      var response = await this.http.get(API_URL + controller + "/" + action, HttpOptions).toPromise();
      return response;
  }

  public async AsyncPost<TModel>(controller: string, action: string, request: TModel) {
    var response = await this.http.post<TModel>(API_URL + controller + "/" + action, request, HttpOptions).toPromise();
    return response;
  }

  public async Save<TModel extends IBaseModel>(controller: string, dto: any, properties: Array<string> = null): Promise<TModel> {
    var request = <TModel>{};
    request.Dto = dto;
    request.Properties = properties;
    var response = await this.AsyncPost<TModel>(controller, Action.Save, request);
    return response;
  }

  public async Delete<TModel extends IBaseModel>(controller: string, dto: any): Promise<TModel> {
    var request = <TModel>{};
    request.Dto = dto;
    var response = await this.AsyncPost<TModel>(controller, Action.Delete, request);
    return response;
  }

  public async Get<TModel extends IBaseModel>(controller: string, search?: string, filter?: any): Promise<TModel> {
    var request = <TModel>{};
    request.Search = search;
    request.Filter = filter;
    var response = await this.AsyncPost<TModel>(controller, Action.Get, request);
    return response;
  }

  public async Read<TModel extends IBaseModel>(controller: string, search?: string, filter?: any): Promise<TModel> {
    var request = <TModel>{};
    request.Search = search;
    request.Filter = filter;
    var response = await this.AsyncPost<TModel>(controller, Action.Read, request);
    return response;
  }

  public async Load<TModel extends IBaseModel>(controller: string, paging: Paging, sortings: Array<Sorting>, search: string, filter: any): Promise<TModel> {
    var request = <TModel>{};
    request.Paging = paging;
    request.Sortings = sortings;
    request.Search = search;
    request.Filter = filter;
    var response = await this.AsyncPost<TModel>(controller, Action.Load, request);
    return response;
  }

  public async Count<TModel extends IBaseModel>(controller: string, search: string, filter: any): Promise<number> {
    var count = 0;
    var request = <TModel>{};
    request.Search = search;
    request.Filter = filter;
    var response = await this.AsyncPost<TModel>(controller, Action.Count, request);
    if (response != null)
      count = response.Count;
    return count;
  }

}


