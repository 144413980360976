import { OnInit } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { DataService } from '../services/data.service';
import { NavigatorService } from '../services/navigator.service';
var ContainerComponent = /** @class */ (function () {
    function ContainerComponent(media, dataService, navigatorService) {
        var _this = this;
        this.media = media;
        this.dataService = dataService;
        this.navigatorService = navigatorService;
        this.isVisible = true;
        this.visibility = 'shown';
        this.sideNavOpened = true;
        this.matDrawerOpened = false;
        this.matDrawerShow = true;
        this.sideNavMode = 'side';
        //impostazioni di autenticazione
        this.navigatorService.Start();
        this.dataService.GetAuthentication().then(function (model) {
            if (model != null) {
                var dto = model.Dto;
                if (dto != null) {
                    var autenticated = dto.Authenticated;
                    if (autenticated) {
                        _this.navigatorService.SetUserData(model);
                    }
                }
            }
            else {
                _this.navigatorService.GoTo("/login");
            }
        });
    }
    ContainerComponent.prototype.ngOnChanges = function () {
        this.visibility = this.isVisible ? 'shown' : 'hidden';
    };
    ContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.media.subscribe(function (mediaChange) {
            _this.ToggleView();
        });
    };
    ContainerComponent.prototype.ToggleView = function () {
        if (this.media.isActive('gt-md')) { //desktop
            this.sideNavMode = 'side';
            this.sideNavOpened = true;
            this.matDrawerOpened = false;
            this.matDrawerShow = true;
            this.navigatorService.DesktopMode = true;
            this.navigatorService.TabletMode = false;
            this.navigatorService.SmartphoneMode = false;
        }
        else if (this.media.isActive('gt-xs')) { //tablet
            this.sideNavMode = 'side';
            this.sideNavOpened = false;
            this.matDrawerOpened = true;
            this.matDrawerShow = true;
            this.navigatorService.DesktopMode = false;
            this.navigatorService.TabletMode = true;
            this.navigatorService.SmartphoneMode = false;
        }
        else if (this.media.isActive('lt-sm')) { //smartphone
            this.sideNavMode = 'over';
            this.sideNavOpened = false;
            this.matDrawerOpened = false;
            this.matDrawerShow = false;
            this.navigatorService.DesktopMode = false;
            this.navigatorService.TabletMode = false;
            this.navigatorService.SmartphoneMode = true;
        }
    };
    return ContainerComponent;
}());
export { ContainerComponent };
